import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {RegisterComponent} from './register/register.component';
import {LoginComponent} from './login/login.component';
import {HomeComponent} from './home/home.component';
import {ProfileComponent} from './profile/profile.component';
import {ActivitiesComponent} from './activities/activities.component';
import {WeeklyGoalsComponent} from './weekly_goals/weekly_goals.component';
import {FoodViewComponent} from './food_view/food_view.component';
import {AllUsersComponent} from './all-users/all-users.component';
import {AuthGuardBaseService} from './_services/auth-guard-base.service';
import {AuthGuardSuperService} from './_services/auth-guard-super.service';
import {UnauthorizedComponent} from './unauthorized/unauthorized.component';
import {ArticlesComponent} from './articles/articles.component';
import {UserPasswordResetComponent} from './user-password-reset/user-password-reset.component';
import {AuthGuardMasterService} from './_services/auth-guard-master.service';
import {AdminForgotPasswordComponent} from './admin-forgot-password/admin-forgot-password.component';
import {LoginGuardService} from './_services/login-guard.service';
import {VideosComponent} from './videos/videos.component';
import {PasswordResetSuccessMessageComponent} from './password-reset-success-message/password-reset-success-message.component';

const routes: Routes = [
  {path: 'home', component: HomeComponent, canActivate: [AuthGuardBaseService]},
  {path: 'login', component: LoginComponent, canActivate: [LoginGuardService]},
  // {path: 'register', component: RegisterComponent},
  {path: 'register', component: RegisterComponent, canActivate: [AuthGuardMasterService]},
  {path: 'profile', component: ProfileComponent, canActivate: [AuthGuardBaseService]},
  {path: 'activities', component: ActivitiesComponent, canActivate: [AuthGuardSuperService]},
  {path: 'foods', component: FoodViewComponent, canActivate: [AuthGuardSuperService]},
  {path: 'goals', component: WeeklyGoalsComponent, canActivate: [AuthGuardSuperService]},
  {path: 'articles', component: ArticlesComponent, canActivate: [AuthGuardSuperService]},
  {path: 'videos', component: VideosComponent, canActivate: [AuthGuardSuperService]},
  {path: 'users', component: AllUsersComponent, canActivate: [AuthGuardBaseService]},
  {path: 'unauthorized', component: UnauthorizedComponent},
  {path: 'resetSuccess/:type', component: PasswordResetSuccessMessageComponent},
  {path: 'updatePassword/:type/:token', component: UserPasswordResetComponent},
  {path: 'forgotPassword', component: AdminForgotPasswordComponent},
  {path: '', redirectTo: 'home', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
