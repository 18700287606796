<div class="container">
  <p>
    <strong>Email:</strong>
    {{ currentUser.email }}
  </p>
  <strong>Permissions:</strong>
  <ul>
    <li *ngIf="viewUserData">View user data</li>
    <li *ngIf="manageAppContent">Manage app content</li>
    <li *ngIf="manageAdministrators">Manage administrators</li>
    <!--    <li *ngFor="let role of currentUser.roles">-->
    <!--      {{ role }}-->
    <!--    </li>-->
  </ul>
</div>
<mat-card>
  <mat-card-content>
    <div class="header">
      <h1>CHANGE PASSWORD</h1>
    </div>
    <form name="changePasswordForm" [formGroup]="changePasswordForm">

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Current Password</mat-label>
          <input formControlName="currentPassword" matInput [type]=" hideCurrent ? 'password' : 'text'" required/>
          <button mat-icon-button matSuffix (click)="changeVisibilityCurrentPassword($event)"
                  [attr.aria-label]="'Hide Password'"
                  [attr.aria-pressed]="hideCurrent">
            <mat-icon>
              {{hideCurrent ? 'visibility_off' : 'visibility'}}
            </mat-icon>
          </button>
          <ng-container
            *ngIf="!passwordChangeSuccess && !changePasswordForm.controls['currentPassword'].valid && (changePasswordForm.controls['currentPassword'].dirty || changePasswordForm.controls['currentPassword'].touched)">
            <ng-container *ngIf="changePasswordForm.controls['currentPassword'].errors.required">
              <mat-error>
                Password is required
              </mat-error>
            </ng-container>
          </ng-container>
        </mat-form-field>


        <mat-form-field class="full-width" appearance="outline">
          <mat-label>New Password</mat-label>
          <input formControlName="newPassword" matInput [type]=" hideNew ? 'password' : 'text'" required autocomplete="new-password"/>
          <button mat-icon-button matSuffix (click)="changeVisibilityNewPassword($event)"
                  [attr.aria-label]="'Hide Password'"
                  [attr.aria-pressed]="hideNew">
            <mat-icon>
              {{hideNew ? 'visibility_off' : 'visibility'}}
            </mat-icon>
          </button>
          <ng-container
            *ngIf="!passwordChangeSuccess && !changePasswordForm.controls['newPassword'].valid && (changePasswordForm.controls['newPassword'].dirty || changePasswordForm.controls['newPassword'].touched)">
            <ng-container *ngIf="changePasswordForm.controls['newPassword'].errors.required">
              <mat-error>
                Password is required
              </mat-error>
            </ng-container>
            <ng-container
              *ngIf="changePasswordForm.controls['newPassword'].errors.minlength && !changePasswordForm.controls['newPassword'].errors.required">
              <mat-error>
                Password must be at least 6 characters
              </mat-error>
            </ng-container>
          </ng-container>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Confirm New Password</mat-label>
          <input formControlName="confirmPassword" matInput [type]=" hideNew ? 'password' : 'text'" required autocomplete="nope"/>
          <button mat-icon-button matSuffix (click)="changeVisibilityNewPassword($event)"
                  [attr.aria-label]="'Hide Password'"
                  [attr.aria-pressed]="hideNew">
            <mat-icon>
              {{hideNew ? 'visibility_off' : 'visibility'}}
            </mat-icon>
          </button>
          <ng-container
            *ngIf="!changePasswordForm.controls['confirmPassword'].valid && (changePasswordForm.controls['confirmPassword'].dirty || changePasswordForm.controls['confirmPassword'].touched)">
            <ng-container
              *ngIf="changePasswordForm.controls['confirmPassword'].errors.notEquivalent && !changePasswordForm.controls['confirmPassword'].errors.required">
              <mat-error>
                Passwords do not match
              </mat-error>
            </ng-container>
          </ng-container>
        </mat-form-field>
        <div class="alert alert-danger" *ngIf="submitted && passwordChangeFailed && !passwordChangeSuccess">
          Incorrect current password!
        </div>

        <div class="alert alert-success" *ngIf="submitted && !passwordChangeFailed && passwordChangeSuccess">
          Password changed successfully!
        </div>
        <br>
      <button class="wide" mat-flat-button color="primary" [disabled]="submitted && passwordChangeSuccess" (click)="onSubmit()">Change Password</button>
    </form>
  </mat-card-content>
</mat-card>
