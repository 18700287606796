import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../_services/user.service';
import {WeeklyGoal} from '../models/WeeklyGoal';
import {MatTableDataSource} from '@angular/material/table';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TokenStorageService} from '../_services/token-storage.service';
import {Title} from '@angular/platform-browser';
import {EncrDecrService} from '../_services/encr-decr-service.service';

@Component({
  selector: 'app-user-password-reset',
  templateUrl: './user-password-reset.component.html',
  styleUrls: ['./user-password-reset.component.css']
})
export class UserPasswordResetComponent implements OnInit {

  // token: string;
  // form: any = {
  //   password: null
  //   // confirmPassword: null
  // };
  pass: string;
  isSuccessful = false;
  resetFailed = false;
  errorMessage = '';
  hide = true;
  submitted = false;
  isUserPage = false;
  resetForm: FormGroup = this.fb.group({
    password: ['', [Validators.required, Validators.minLength(6)]],
    confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
  }, {validator: this.checkIfMatchingPasswords('password', 'confirmPassword'), });

  constructor(private userService: UserService, private fb: FormBuilder, private titleService: Title, private encrDecr: EncrDecrService, private router: Router) {
    this.titleService.setTitle('Reset Password');
  }

  ngOnInit(): void {
    const url = window.location.toString().split('/');
    const token = url[url.length - 1];
  }

  onSubmit(): void {
    this.submitted = true;
    if (!this.resetForm.valid) {
      return;
    } else {
      const url = window.location.toString().split('/');
      const token = url[url.length - 1];
      const type = url[url.length - 2];
      const password = this.resetForm.get('password').value;
      if (type === 'user') {
        this.isUserPage = true;
        const encryptedPassword = this.encrDecr.set('oKLS5V5QycG3MYzZYhVCbSpvDeQxx5xk', password);
        this.userService.resetUserPassword(encryptedPassword, token).subscribe(
          data => {
            this.isSuccessful = true;
            this.resetFailed = false;
            this.router.navigate(['resetSuccess/' + 'success']);
          },
          err => {
            this.errorMessage = err.error.message;
            this.resetFailed = true;
          }
        );
      } else if (type === 'admin') {
        this.userService.resetAdministratorPassword(password, token).subscribe(
          data => {
            this.isSuccessful = true;
            this.resetFailed = false;
            this.router.navigate(['resetSuccess/' + type]);
          },
          err => {
            this.errorMessage = err.error.message;
            this.resetFailed = true;
          }
        );
      }
    }
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string): any {
    return (group: FormGroup) => {
      const passwordInput = group.controls[passwordKey];
      const passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({notEquivalent: true});
      }
      else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }


  changeVisibility(event): void {
    if (event.clientX !== 0) {
      this.hide = !this.hide;
    }
  }


}
