<head>
  <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500|Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp">
</head>

<div class="container py-5 center">
  <div class="row">
    <div class="message">
      <h1 style="font-size: 40px">Password Reset Successful!</h1>

      <ng-container *ngIf="isAdmin">
        <h3>You password has been successfully reset.<br/><a href="/home">Click this to return to the login screen.</a></h3>
<!--        <button class="btn btn-primary" (click)="goToLogin()">Go to login</button>-->
      </ng-container>

      <ng-container *ngIf="!isAdmin">
        <h3>You password has been successfully reset.<br/>You can now return to the Cancer DietAssist app to login.</h3>
      </ng-container>

    </div>
  </div>
</div>
