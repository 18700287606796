export class Food {
  id: number;

  baseId: number;

  description: string;

  keyList: string;

  kcal: number;

  proteinInGrams: number;

  fatInGrams: number;

  carbohydratesInGrams: number;

  fiberInGrams: number;

  solubleFiberInGrams: number;

  insolubleFiberInGrams: number;

  calciumInMilligrams: number;

  sodiumInMilligrams: number;

  saturatedFattyAcidsInGrams: number;

  polyunsaturatedFattyAcidsInGrams: number;

  monounsaturatedFattyAcidsInGrams: number;

  sugarInGrams: number;

  alcoholInGrams: number;

  vitaminDInMicrograms: number;

  commonPortionSizeAmount: number;

  commonPortionSizeGramWeight: number;

  commonPortionSizeDescription: string;

  commonPortionSizeUnit: string;

  nccFoodGroupCategory: string;

  constructor(
    id: number,
    baseId: number,
    description: string,
    // keyList: string,
    // kcal: number,
    // proteinInGrams: number,
    // fatInGrams: number,
    // carbohydratesInGrams: number,
    // fiberInGrams: number,
    // solubleFiberInGrams: number,
    // insolubleFiberInGrams: number,
    // calciumInMilligrams: number,
    // sodiumInMilligrams: number,
    // saturatedFattyAcidsInGrams: number,
    // polyunsaturatedFattyAcidsInGrams: number,
    // monounsaturatedFattyAcidsInGrams: number,
    // sugarInGrams: number,
    // alcoholInGrams: number,
    // vitaminDInMicrograms: number,
    // commonPortionSizeAmount: number,
    // commonPortionSizeGramWeight: number,
    // commonPortionSizeDescription: string,
    // commonPortionSizeUnit: string,
    // nccFoodGroupCategory: string,
  ) {
    this.id = id;
    this.baseId = baseId;
    this.description = description;
      // description: string,
      // keyList: string,
      // kcal: number,
      // proteinInGrams: number,
      // fatInGrams: number,
      // carbohydratesInGrams: number,
      // fiberInGrams: number,
      // solubleFiberInGrams: number,
      // insolubleFiberInGrams: number,
      // calciumInMilligrams: number,
      // sodiumInMilligrams: number,
      // saturatedFattyAcidsInGrams: number,
      // polyunsaturatedFattyAcidsInGrams: number,
      // monounsaturatedFattyAcidsInGrams: number,
      // sugarInGrams: number,
      // alcoholInGrams: number,
      // vitaminDInMicrograms: number,
      // commonPortionSizeAmount: number,
      // commonPortionSizeGramWeight: number,
      // commonPortionSizeDescription: string,
      // commonPortionSizeUnit: string,
      // nccFoodGroupCategory: string,
  }
}
