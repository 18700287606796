import {Component, OnInit} from '@angular/core';
import {AuthService} from '../_services/auth.service';
import {TokenStorageService} from '../_services/token-storage.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: any = {
    username: null,
    password: null
  };
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  email: string;
  username: string;
  hide = true;
  submitted = false;

  loginForm: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]]
  });

  // tslint:disable-next-line:max-line-length
  constructor(private authService: AuthService, private tokenStorage: TokenStorageService, private fb: FormBuilder, private titleService: Title, private router: Router) {
    this.titleService.setTitle('Login');
  }

  ngOnInit(): void {
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorage.getUser().roles;
      this.roles = this.tokenStorage.getUser().roles;
      this.email = this.tokenStorage.getUser().email;
      this.username = this.tokenStorage.getUser().username;
      // setTimeout(() => {
      window.location.href = '';
      // }, 2000);
    }
  }

  onSubmit(): void {
    this.submitted = true;
    if (!this.loginForm.valid) {
      return;
    } else {
      const username = this.loginForm.get('email').value;
      const password = this.loginForm.get('password').value;
      this.authService.login(username, password).subscribe(
        data => {
          this.tokenStorage.saveToken(data.accessToken);
          this.tokenStorage.saveUser(data);

          this.isLoginFailed = false;
          this.isLoggedIn = true;
          this.roles = this.tokenStorage.getUser().roles;
          this.router.navigate(['']);
          // window.location.href = '';
          window.location.reload();
        },
        err => {
          this.errorMessage = err.error.message;
          this.isLoginFailed = true;
        }
      );
    }
  }

  changeVisibility(event): void {
    if (event.clientX !== 0) {
      this.hide = !this.hide;
    }
  }

  reloadPage(): void {
    window.location.reload();
  }
}
