<head>
  <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500|Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp">
</head>

<div class="container py-5 center">
  <div class="row">
    <div>
      <p class="status"><i class="material-icons-round md-48">warning</i><br/>Status Code: 403</p>
    </div>
    <div class="message">
      <h1>Unauthorized</h1>
      <p>You do not have access to the requested resource.<br/>Please go back to the previous page to continue browsing.</p>
      <button class="btn btn-danger" (click)="goBack()">Go Home</button>
    </div>
  </div>
</div>
