import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {TokenStorageService} from './token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardMasterService implements CanActivate{
private roles: string[] = [];
  isLoggedIn = false;
  showBaseBoard = false;
  showSuperBoard = false;
  showMasterBoard = false;

  constructor(private router: Router,
              private tokenStorageService: TokenStorageService) { }

// tslint:disable-next-line:typedef
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.isLoggedIn = !!this.tokenStorageService.getToken();
    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      this.roles = user.roles;
      this.showBaseBoard = this.roles.includes('ROLE_BASE');
      this.showSuperBoard = this.roles.includes('ROLE_SUPER');
      this.showMasterBoard = this.roles.includes('ROLE_MASTER');
      if (this.showMasterBoard) {
        return true;
      } else {
        this.router.navigate(['unauthorized']);
        return false;
      }
    } else{
      this.router.navigate(['login']);
      return false;
    }

  }
}
