import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {UserService} from '../_services/user.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {MatTableDataSource} from '@angular/material/table';
import {Video} from '../models/Video';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {HttpEvent} from '@angular/common/http';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit {

  videos: Video[] = [];
  video: Video;
  displayedColumns: string[] = ['id', 'title', 'subject', 'type', 'url', 'actions'];
  dataSource: any;
  changeImage = false;
  isLoading = false;


  @ViewChild(MatPaginator) paginator: MatPaginator;

  // tslint:disable-next-line:max-line-length
  constructor(private userService: UserService, public createDialog: MatDialog, private snackBar: MatSnackBar, private sanitizer: DomSanitizer, private titleService: Title) {
    this.titleService.setTitle('Videos');
  }

  ngOnInit(): void {
    this.getVideos();
  }

  getVideos(): void {
    this.isLoading = true;
    this.userService.getVideos().subscribe(videos => {
      this.videos = [];
      for (const val of videos) {
        // tslint:disable-next-line:max-line-length
        const video = new Video(val.id, val.videoName, val.videoSubject, val.videoType, val.videoUrl);
        this.videos.push(video);
      }
      this.dataSource = new MatTableDataSource<Video>(this.videos);
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    });
  }


  openCreateDialog(): void {
    const newVideo = new Video(0, '', '', '', '');
    const dialogRef = this.createDialog.open(AddVideoDialogComponent, {
      width: '600px',
      height: '650px',
      data: newVideo
    });
    // this.createDialog.open(AddActivityDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.openSnackBar('Video Created Successfully', 'Close');
        this.getVideos();
      }
    });
  }

  openEditDialog(video): void {
    const dialogRef = this.createDialog.open(EditVideoDialogComponent, {
      width: '600px',
      height: '560px',
      data: video
    });
    // this.createDialog.open(AddActivityDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.openSnackBar('Video Updated Successfully', 'Close');
        this.getVideos();
      }
    });
  }

  // tslint:disable-next-line:typedef
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  openDeleteDialog(video): void {
    const dialogRef = this.createDialog.open(DeleteVideoDialogComponent, {
      width: '600px',
      height: '300px',
      data: video
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.video == null) {
          this.userService.deleteVideo(video.id).subscribe(event => {
              this.isLoading = true;
              this.openSnackBar('Video Deleted Successfully', 'Close');
              this.getVideos();
            }
          );
        }
      }
    });
  }

}


@Component({
  selector: 'app-add-video-dialog',
  templateUrl: 'app-add-video-dialog.html',
})
export class AddVideoDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AddVideoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddDialogData, private fb: FormBuilder, private userService: UserService) {
    this.dialogRef.backdropClick().subscribe(result => {
      dialogRef.close(this.isSuccessful);
    });
  }

  isLoading = false;
  videoName: string;
  videoSubject: string;
  videoType: string;
  videoUrl: string;
  visible = true;

  username: string;
  isSuccessful = false;
  activityCreationFailed = false;
  errorMessage = '';
  submitted = false;
  pattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  videoForm: FormGroup = this.fb.group({
    videoName: ['', [Validators.required]],
    videoSubject: ['', [Validators.required]],
    videoType: ['', [Validators.required]],
    videoUrl: ['', [Validators.required, Validators.pattern(this.pattern)]],
  });





  onNoClick(): void {
    this.dialogRef.close(this.isSuccessful);
  }

  onSubmit(): void {
    this.submitted = true;
    if (!this.videoForm.valid) {
      return;
    } else {
      const videoName = this.videoForm.get('videoName').value;
      const videoSubject = this.videoForm.get('videoSubject').value;
      const videoType = this.videoForm.get('videoType').value;
      const videoUrl = this.videoForm.get('videoUrl').value;

      this.userService.createVideo(videoName, videoSubject, videoType, videoUrl).subscribe(data => {
          this.activityCreationFailed = false;
          this.isSuccessful = true;
          this.isLoading = true;
        }, err => {
          this.errorMessage = err.error.message;
          this.errorMessage = 'Error: Could not create video';
          this.activityCreationFailed = true;
        }
      );
    }
  }
}

export interface AddDialogData {
  video: Video;
}

@Component({
  selector: 'app-edit-video-dialog',
  templateUrl: 'app-edit-video-dialog.html',
})
export class EditVideoDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<EditVideoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditDialogData, private fb: FormBuilder, private userService: UserService) {
    this.dialogRef.backdropClick().subscribe(result => {
      dialogRef.close(this.isSuccessful);
    });
  }

  file: File;
  isLoading = false;
  videoName: string;
  videoSubject: string;
  videoType: string;
  videoUrl: string;
  visible: boolean;

  username: string;
  isSuccessful = false;
  videoCreationFailed = false;
  errorMessage = '';
  submitted = false;
  pattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  videoForm: FormGroup = this.fb.group({
    videoName: ['', [Validators.required]],
    videoSubject: ['', [Validators.required]],
    videoType: ['', [Validators.required]],
    videoUrl: ['', [Validators.required, Validators.pattern(this.pattern)]],
  });

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.submitted = true;
    if (!this.videoForm.valid) {
      return;
    } else {
      const videoName = this.videoForm.get('videoName').value;
      const videoUrl = this.videoForm.get('videoUrl').value;
      const videoSubject = this.videoForm.get('videoSubject').value;
      const videoType = this.videoForm.get('videoType').value;
      this.userService.editVideo(this.data.id, videoName, videoSubject, videoType, videoUrl).subscribe(data => {
          this.videoCreationFailed = false;
          this.isSuccessful = true;
          this.isLoading = true;
          this.file = undefined;
        }, err => {
          this.errorMessage = err.error.message;
          this.errorMessage = 'Error: Could not create video';
          this.videoCreationFailed = true;
        }
      );
    }
  }
}

export interface EditDialogData {
  video: Video;
  videoName: any;
  videoSubject: any;
  videoType: any;
  videoUrl: any;
  visible: any;
  id: any;
}

@Component({
  selector: 'app-delete-video-dialog',
  templateUrl: 'app-delete-video-dialog.html',
})
export class DeleteVideoDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DeleteVideoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditDialogData) {
  }

  confirm(): void {
    this.data.video = null;
    // this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

export interface EditDialogData {
  video: Video;
  videoName: any;
  videoAuthor: any;
  videoSubject: any;
  videoType: any;
  visible: any;
}
