import {Component, OnInit, ViewChild} from '@angular/core';
import {Food} from '../models/Food';
import {MatPaginator} from '@angular/material/paginator';
import {UserService} from '../_services/user.service';
import {MatTableDataSource} from '@angular/material/table';
import {User} from '../models/User';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-all-users',
  templateUrl: './all-users.component.html',
  styleUrls: ['./all-users.component.css']
})
export class AllUsersComponent implements OnInit {

  content?: string;

  users: User[] = [];
  displayedColumns: string[] = ['id', 'getdetails'];

  dataSource: any;

  isLoading = false;

  file: File;
  submitDisabled = true;

  selectedFiles: FileList;
  currentFileUpload: File;
  selectedFile = null;


  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private userService: UserService, private titleService: Title) {
    this.titleService.setTitle('Users');
  }

  ngOnInit(): void {
    // this.isLoading = true;
    this.getUsers();
  }

  getUsers(): void {
    this.userService.getUsers().subscribe(users => {
      this.users = [];
      for (const val of users) {
        const user = new User(val.id);
        this.users.push(user);
      }
      this.dataSource = new MatTableDataSource<User>(this.users);
      this.dataSource.paginator = this.paginator;
      // this.isLoading = false;
    });
  }

  getDetails(id: number): void {
    console.log(id);
  }

}
