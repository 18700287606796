<mat-card>
  <mat-card-content>
    <div class="header">
      <h1>FORGOT PASSWORD</h1>
    </div>
    <form (keyup.enter)="onSubmit()" name="requestForm" [formGroup]="requestForm">
      <div class="emailInput">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Email</mat-label>
          <input
            formControlName="email"
            matInput
            placeholder="Enter email address" required
          />
          <ng-container
            *ngIf="!requestForm.controls['email'].valid && (requestForm.controls['email'].dirty || requestForm.controls['email'].touched)">
            <ng-container *ngIf="requestForm.controls['email'].errors.required">
              <mat-error>
                Email is required
              </mat-error>
            </ng-container>
            <ng-container *ngIf="requestForm.controls['email'].errors.email && !requestForm.controls['email'].errors.required">
              <mat-error>
                Invalid Email
              </mat-error>
            </ng-container>
          </ng-container>
        </mat-form-field>
      </div>

      <div>
        <div class="alert alert-danger" *ngIf="submitted && requestFailed && !requestSuccess">
          Email not found!<br/>{{ errorMessage }}
        </div>

        <div class="alert alert-success" *ngIf="requestSuccess && submitted && !requestFailed">
          {{successMessage}}
        </div>
        <br>
      </div>
      <button class="wide" mat-flat-button color="primary" (click)="onSubmit()">Request Password Reset</button>
    </form>
  </mat-card-content>
</mat-card>
