import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {ActivityOption} from '../models/ActivityOption';
import {catchError, tap} from 'rxjs/operators';
import {WeeklyGoal} from '../models/WeeklyGoal';
import {Food} from '../models/Food';
import {User} from '../models/User';
import {Article} from '../models/Article';
import {Video} from '../models/Video';

const API_URL = 'https://enact-crc-app.herokuapp.com/api/';
// const API_URL = 'http://localhost:5000/api/';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient) {
  }

  /** GET ActivityOptions from the server */
  getActivityOptions(): Observable<ActivityOption[]> {
    return this.http.get<ActivityOption[]>(API_URL + 'activityOptions/all')
      .pipe(
        tap(_ => this.log('fetched ActivityOptions')),
        catchError(this.handleError<ActivityOption[]>('getActivityOptions', []))
      );
  }

  /** GET WeeklyGoals from the server */
  getWeeklyGoals(): Observable<WeeklyGoal[]> {
    return this.http.get<WeeklyGoal[]>(API_URL + 'weekly_goals/all')
      .pipe(
        tap(_ => this.log('fetched WeeklyGoals')),
        catchError(this.handleError<WeeklyGoal[]>('getWeeklyGoals', []))
      );
  }

  /** GET Food from the server */
  getFood(): Observable<Food[]> {
    return this.http.get<Food[]>(API_URL + 'food/all')
      .pipe(
        tap(_ => this.log('fetched food')),
        catchError(this.handleError<Food[]>('getFood', []))
      );
  }

  /** GET Users from the server */
  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(API_URL + 'users/all')
      .pipe(
        tap(_ => this.log('fetched users')),
        catchError(this.handleError<User[]>('getUsers', []))
      );
  }

  /** GET Articles from the server */
  getArticles(): Observable<Article[]> {
    return this.http.get<Article[]>(API_URL + 'article/all')
      .pipe(
        tap(_ => this.log('fetched articles')),
        catchError(this.handleError<Article[]>('getArticles', []))
      );
  }

  /** GET Videos from the server */
  getVideos(): Observable<Video[]> {
    return this.http.get<Video[]>(API_URL + 'video/all')
      .pipe(
        tap(_ => this.log('fetched videos')),
        catchError(this.handleError<Video[]>('getVideos', []))
      );
  }

  // tslint:disable-next-line:typedef
  public createActivity(activity) {
    return this.http.post(API_URL + 'activityOptions/create', activity, {responseType: 'text' as 'json'});
  }

  // tslint:disable-next-line:typedef
  public editActivity(activity) {
    return this.http.post(API_URL + 'activityOptions/edit', activity, {responseType: 'text' as 'json'});
  }

  // tslint:disable-next-line:typedef
  public createGoal(goal) {
    return this.http.post(API_URL + 'weekly_goals/create', goal, {responseType: 'text' as 'json'});
  }

  // tslint:disable-next-line:typedef
  public editGoal(goal) {
    return this.http.post(API_URL + 'weekly_goals/edit', goal, {responseType: 'text' as 'json'});
  }

  pushFileToStorage(file: File): Observable<HttpEvent<{}>> {
    const data: FormData = new FormData();
    data.append('file', file);
    const newRequest = new HttpRequest('POST', API_URL + 'food/upload', data, {
      reportProgress: true,
      responseType: 'text'
    });
    return this.http.request(newRequest);
  }

  // tslint:disable-next-line:max-line-length
  pushPdfToStorage(file: File, articleName: string, articleAuthor: string, articleSubject: string, articleType: string, isVisible: boolean): Observable<HttpEvent<{}>> {
    const data: FormData = new FormData();
    data.append('file', file);
    data.append('articleName', articleName);
    data.append('articleAuthor', articleAuthor);
    data.append('articleSubject', articleSubject);
    data.append('articleType', articleType);
    data.append('isVisible', String(isVisible));
    const newRequest = new HttpRequest('POST', API_URL + 'article/upload', data, {
      reportProgress: true,
      responseType: 'text'
    });
    return this.http.request(newRequest);
  }

  editPdfInStorage(id: string, article): Observable<HttpEvent<{}>> {
    const data: FormData = new FormData();
    // const editedArticle: Article = new Article(+id, article.title, article.author, article.topic, article.article.file);
    data.append('id', id);
    data.append('articleName', article.articleName);
    data.append('articleAuthor', article.articleAuthor);
    data.append('articleSubject', article.articleSubject);
    data.append('articleType', article.articleType);
    data.append('isVisible', String(article.visible));
    const newRequest = new HttpRequest('POST', API_URL + 'article/edit', data, {
      reportProgress: true,
      responseType: 'text'
    });
    return this.http.request(newRequest);
  }

   deleteArticle(articleId: number): any {
    const url = API_URL + 'article/delete/' + articleId;
    return this.http.delete(url,  {responseType: 'text' as 'json'});
  }

  createVideo(videoName: string, videoSubject: string, videoType: string, videoUrl: string): Observable<HttpEvent<{}>> {
    const data: FormData = new FormData();
    data.append('videoName', videoName);
    data.append('videoSubject', videoSubject);
    data.append('videoType', videoType);
    data.append('videoUrl', videoUrl);
    const newRequest = new HttpRequest('POST', API_URL + 'video/upload', data, {
      reportProgress: true,
      responseType: 'text'
    });
    return this.http.request(newRequest);
  }

  editVideo(id: string, videoName: string, videoSubject: string, videoType: string, videoUrl: string): Observable<HttpEvent<{}>> {
    const data: FormData = new FormData();
    data.append('id', id);
    data.append('videoName', videoName);
    data.append('videoSubject', videoSubject);
    data.append('videoType', videoType);
    data.append('videoUrl', videoUrl);
    const newRequest = new HttpRequest('POST', API_URL + 'video/edit', data, {
      reportProgress: true,
      responseType: 'text'
    });
    return this.http.request(newRequest);
  }

  deleteVideo(videoId: number): any {
    const url = API_URL + 'video/delete/' + videoId;
    return this.http.delete(url,  {responseType: 'text' as 'json'});
  }

  // getPublicContent(): Observable<any> {
  //   return this.http.get(API_URL2 + 'all', {responseType: 'text'});
  // }
  //
  // getUserBoard(): Observable<any> {
  //   return this.http.get(API_URL2 + 'base', {responseType: 'text'});
  // }
  //
  // getModeratorBoard(): Observable<any> {
  //   return this.http.get(API_URL2 + 'super', {responseType: 'text'});
  // }
  //
  // getAdminBoard(): Observable<any> {
  //   return this.http.get(API_URL2 + 'master', {responseType: 'text'});
  // }

  // tslint:disable-next-line:typedef
  log(message: string) {
    // console.log(message);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  // tslint:disable-next-line:typedef
  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


  resetUserPassword(password: string, token: string): any {
    const httpOptions2 = {
      params: {
        // tslint:disable-next-line
        password: password,
        // tslint:disable-next-line
        token: token
      },
    };
    return this.http.put(API_URL + 'users/updatePassword/', {
    }, httpOptions2);

  }

  resetAdministratorPassword(password: string, token: string): any {
    const httpOptions2 = {
      params: {
        // tslint:disable-next-line
        password: password,
        // tslint:disable-next-line
        token: token
      },
    };
    return this.http.put(API_URL + 'administrators/updatePassword/', {
    }, httpOptions2);

  }

  requestAdministratorPasswordChange(email: string): any {
    const httpOptions2 = {
      params: {
        // tslint:disable-next-line
        email: email,
      },
    };
    return this.http.post(API_URL + 'administrators/resetPassword/', {
    }, httpOptions2);

  }

}
