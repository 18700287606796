<!--<div class="col-md-12">-->
<!--  <div class="card card-container">-->

<!--    <form-->
<!--      *ngIf="!isSuccessful"-->
<!--      name="form"-->
<!--      (ngSubmit)="f.form.valid && onSubmit()"-->
<!--      #f="ngForm"-->
<!--      novalidate-->
<!--    >-->

<!--      <div class="form-group">-->
<!--        <label for="email">Email</label>-->
<!--        <input-->
<!--          type="email"-->
<!--          class="form-control"-->
<!--          name="email"-->
<!--          [(ngModel)]="form.email"-->
<!--          required-->
<!--          email-->
<!--          #email="ngModel"-->
<!--        />-->
<!--        <div class="alert-danger" *ngIf="email.errors && f.submitted">-->
<!--          <div *ngIf="email.errors.required">Email is required</div>-->
<!--          <div *ngIf="email.errors.email">-->
<!--            Email must be a valid email address-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="form-group">-->
<!--        <label for="password">Password</label>-->
<!--        <input-->
<!--          type="password"-->
<!--          class="form-control"-->
<!--          name="password"-->
<!--          [(ngModel)]="form.password"-->
<!--          required-->
<!--          minlength="6"-->
<!--          #password="ngModel"-->
<!--        />-->
<!--        <div class="alert-danger" *ngIf="password.errors && f.submitted">-->
<!--          <div *ngIf="password.errors.required">Password is required</div>-->
<!--          <div *ngIf="password.errors.minlength">-->
<!--            Password must be at least 6 characters-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="form-group">-->
<!--        <label for="role">Permissions</label>-->
<!--        <div class="input-group mb-3">-->
<!--          <select #role="ngModel" required name="role" [(ngModel)]="form.role" class="custom-select">-->
<!--            <option value="user-only">User View Only</option>-->
<!--            <option value="content-manager">User View + Manage App Content</option>-->
<!--            <option *ngIf="this.tokenStorageService.getUser().username.toUpperCase()=='ADMIN@MAIL.COM'" value="master">Master</option>-->
<!--          </select>-->
<!--        </div>-->
<!--        <div class="alert-danger" *ngIf="role.errors && f.submitted">-->
<!--          <div *ngIf="role.errors.required">Role is required</div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="form-group">-->
<!--        <button class="btn btn-primary btn-block">Create Account</button>-->
<!--      </div>-->

<!--      <div class="alert alert-warning" *ngIf="f.submitted && isSignUpFailed">-->
<!--        Signup failed!<br/>{{ errorMessage }}-->
<!--      </div>-->
<!--    </form>-->

<!--    <div class="alert alert-success" *ngIf="isSuccessful">-->
<!--      New Account Created!-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<mat-card>
  <mat-card-content>
    <div class="header">
      <h1>CREATE ACCOUNT</h1>
    </div>
    <form (keyup.enter)="onSubmit()" name="registerForm" [formGroup]="registerForm">
      <div class="emailInput">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Email</mat-label>
          <input
            formControlName="email"
            matInput
            placeholder="Enter email address" required
          />
          <ng-container
            *ngIf="!registerForm.controls['email'].valid && (registerForm.controls['email'].dirty || registerForm.controls['email'].touched)">
            <ng-container *ngIf="registerForm.controls['email'].errors.required">
              <mat-error>
                Email is required
              </mat-error>
            </ng-container>
            <ng-container
              *ngIf="registerForm.controls['email'].errors.email && !registerForm.controls['email'].errors.required">
              <mat-error>
                Invalid Email
              </mat-error>
            </ng-container>
          </ng-container>
        </mat-form-field>
      </div>

      <div>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Password</mat-label>
          <input formControlName="password" matInput [type]=" hide ? 'password' : 'text'" required/>
          <button mat-icon-button matSuffix (click)="changeVisibility($event)" [attr.aria-label]="'Hide Password'"
                  [attr.aria-pressed]="hide">
            <mat-icon>
              {{hide ? 'visibility_off' : 'visibility'}}
            </mat-icon>
          </button>
          <ng-container
            *ngIf="!registerForm.controls['password'].valid && (registerForm.controls['password'].dirty || registerForm.controls['password'].touched)">
            <ng-container *ngIf="registerForm.controls['password'].errors.required">
              <mat-error>
                Password is required
              </mat-error>
            </ng-container>
            <ng-container
              *ngIf="registerForm.controls['password'].errors.minlength && !registerForm.controls['password'].errors.required">
              <mat-error>
                Password must be at least 6 characters
              </mat-error>
            </ng-container>
          </ng-container>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Confirm Password</mat-label>
          <input formControlName="confirmPassword" matInput [type]=" hide ? 'password' : 'text'" required/>
          <button mat-icon-button matSuffix (click)="changeVisibility($event)" [attr.aria-label]="'Hide Password'"
                  [attr.aria-pressed]="hide">
            <mat-icon>
              {{hide ? 'visibility_off' : 'visibility'}}
            </mat-icon>
          </button>
          <ng-container
            *ngIf="!registerForm.controls['confirmPassword'].valid && (registerForm.controls['confirmPassword'].dirty || registerForm.controls['confirmPassword'].touched)">
            <ng-container
              *ngIf="registerForm.controls['confirmPassword'].errors.notEquivalent && !registerForm.controls['confirmPassword'].errors.required">
              <mat-error>
                Passwords do not match
              </mat-error>
            </ng-container>
          </ng-container>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Permissions</mat-label>
          <mat-select formControlName="role">
            <mat-option value="user-only">User View Only</mat-option>
            <mat-option value="content-manager">User View + Manage App Content</mat-option>
            <mat-option *ngIf="this.username.toUpperCase()=='BENEDICTINENUTRITIONAPP@GMAIL.COM'"
                        value="master">Master
            </mat-option>
          </mat-select>
          <ng-container
            *ngIf="!registerForm.controls['role'].valid && (registerForm.controls['role'].dirty || registerForm.controls['role'].touched)">
            <ng-container
              *ngIf="registerForm.controls['role'].errors.required">
              <mat-error>
                Permissions must be selected
              </mat-error>
            </ng-container>
          </ng-container>
        </mat-form-field>

        <div class="alert alert-danger" *ngIf="submitted && isSignUpFailed">
          Signup failed!<br/>{{ errorMessage }}
        </div>
        <div class="alert alert-success" *ngIf="isSuccessful && !isSignUpFailed">
          New Account Created!
        </div>
        <br>
      </div>
      <button (click)="onSubmit()" class="wide" mat-flat-button color="primary">Create Account</button>
    </form>
  </mat-card-content>
</mat-card>

<!--<div class="alert alert-warning" *ngIf="submitted && isSignUpFailed">-->
<!--  Signup failed!<br/>{{ errorMessage }}-->
<!--</div>-->
