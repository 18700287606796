<!--<ng-container *ngIf="isLoading">-->

<!--  <mat-spinner></mat-spinner>-->

<!--</ng-container>-->
<!--<ng-container *ngIf="!isLoading">-->
  <div class="mat-elevation-z8">
    <h1>Food</h1>
    <table mat-table [dataSource]="dataSource">

      <!-- Position Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID</th>
        <td mat-cell *matCellDef="let Food"> {{Food.id}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="baseId">
        <th mat-header-cell *matHeaderCellDef> BaseId</th>
        <td mat-cell *matCellDef="let Food"> {{Food.baseId}} </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> Description</th>
        <td mat-cell *matCellDef="let Food"> {{Food.description}} </td>
      </ng-container>

<!--      <ng-container matColumnDef="actions">-->
<!--        <th mat-header-cell *matHeaderCellDef> Actions</th>-->
<!--        <td mat-cell *matCellDef="let Food">-->
<!--          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">-->
<!--            <mat-icon>more_vert</mat-icon>-->
<!--          </button>-->
<!--        </td>-->
<!--      </ng-container>-->

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
<!--          <button mat-flat-button (click)="openCreateDialog()">ADD</button>-->
          <button mat-stroked-button (click)="openCreateDialog()">Upload File</button>

          <!--          <button mat-mini-fab color="accent" aria-label="Example icon button with a plus one icon">-->
<!--            ADD-->
<!--          </button>-->
<!--          <button mat-fab color="accent" (click)="openCreateDialog()" aria-label="Example icon button with a bookmark icon">-->
<!--            -->
<!--            <mat-icon>bookmark</mat-icon>-->
<!--          </button>-->
<!--          <button mat-icon-button color="primary" (click)="openCreateDialog()">-->
<!--            <mat-icon aria-label="Example icon-button with a heart icon">add</mat-icon>-->
<!--          </button>-->
        </th>
        <td mat-cell *matCellDef="let Food">
<!--          <button mat-icon-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{Food: Food}" aria-label="Example icon-button with a menu">-->
<!--            <mat-icon>more_vert</mat-icon>-->
<!--          </button>-->
        </td>
      </ng-container>


      <mat-menu #menu="matMenu">
        <button mat-menu-item>
          <mat-icon>edit</mat-icon>
          <span>Edit</span>
        </button>
        <button mat-menu-item>
          <mat-icon>delete</mat-icon>
          <span>Delete</span>
        </button>
      </mat-menu>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <button mat-icon-button aria-label="Example icon button with a vertical three dot icon">
        <mat-icon>more_vert</mat-icon>
      </button>
    </table>
    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
      <mat-progress-spinner
        color="primary"
        mode="indeterminate">
      </mat-progress-spinner>
    </mat-card>
    <!--      <mat-paginator [pageSize]="10" showFirstLastButtons></mat-paginator>-->
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

  </div>
<!--</ng-container>-->

<!--<label class="btn btn-default">-->
<!--  <input type="file" (change)="selectFile($event)" />-->
<!--</label>-->

<!--<button class="btn btn-success" [disabled]="!selectedFiles" (click)="upload()">-->
<!--  Upload-->
<!--</button>-->
