<h1 mat-dialog-title>Delete Article</h1>
<div mat-dialog-content>
  <p>Are you sure you want to delete this article?</p>
  <p>ID: <span style="font-weight: bold">{{data.id}}</span></p>
  <p>Title: <span style="font-weight: bold">{{data.articleName}}</span></p>
  <br>
  <p>This action cannot be undone.</p>
</div>
<div mat-dialog-actions>
  <button class="btn" (click)="onNoClick()">Cancel</button>
  <button class="btn btn-primary" [mat-dialog-close]="data" (click)="confirm()" cdkFocusInitial>Confirm</button>
<!--  <button mat-button (click)="onNoClick()">Cancel</button>-->
<!--  <button mat-button [mat-dialog-close]="data" (click)="confirm()" cdkFocusInitial>Confirm</button>-->
</div>


