<style>
  mat-card {
    max-width: 600px;
    margin: 2em auto;
    text-align: center;
    max-height: 600px;
  }

  .header {
    text-align: center;

  }

  .full-width {
    width: 80%;
  }

  .button-row {
    padding-top: 5px;
  }

  .button-row a {
    margin-right: 8px;
    text-align: center;
  }

  .forget-password {
    padding-left: 0px;
  }

  .emailInput {
    padding-top: 10px;
  }

  .contentBody {
    padding: 60px 1rem;
    background: #1b6ca8;
    display: block;
  }

  .aLink {
    float: right;
    padding-right: 60px;
    text-decoration: none;
  }

  .wide {
    width: 85% !important;
  }

</style>
<h1 mat-dialog-title>Edit Goal</h1>
<div mat-dialog-content>
  <mat-card>
    <mat-card-content>
      <form (keyup.enter)="onSubmit()" name="goalForm" [formGroup]="goalForm">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Type</mat-label>
          <mat-select [(ngModel)]="data.type" formControlName="type">
            <mat-option value="Fruits">Fruits</mat-option>
            <mat-option value="Vegetables">Vegetables</mat-option>
            <mat-option value="Protein">Protein</mat-option>
            <mat-option value="Dairy">Dairy</mat-option>
            <mat-option value="Snacks and Condiments">Snacks and Condiments</mat-option>
            <mat-option value="Beverage">Beverage</mat-option>
            <mat-option value="Physical Activity">Physical Activity</mat-option>
            <mat-option value="Grains">Grains</mat-option>
          </mat-select>
          <ng-container
            *ngIf="!goalForm.controls['type'].valid && (goalForm.controls['type'].dirty || goalForm.controls['type'].touched)">
            <ng-container
              *ngIf="goalForm.controls['type'].errors.required">
              <mat-error>
                Type must be selected
              </mat-error>
            </ng-container>
          </ng-container>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Description</mat-label>
          <textarea [(ngModel)]="data.goalDescription" formControlName="goalDescription" matInput cdkTextareaAutosize cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="5" placeholder="Description"></textarea>
          <mat-hint>Description</mat-hint>
          <ng-container
            *ngIf="!goalForm.controls['goalDescription'].valid && (goalForm.controls['goalDescription'].dirty || goalForm.controls['goalDescription'].touched)">
            <ng-container *ngIf="goalForm.controls['goalDescription'].errors.required">
              <mat-error>
                Description is required
              </mat-error>
            </ng-container>
          </ng-container>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Help Info</mat-label>
          <textarea [(ngModel)]="data.help_info" formControlName="helpInfo" matInput cdkTextareaAutosize cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="5" placeholder="Help Info"></textarea>
          <mat-hint>Help Info</mat-hint>
        </mat-form-field>

        <div>


          <div class="alert alert-danger" *ngIf="submitted && goalCreationFailed">
            {{ errorMessage }}
          </div>
          <div class="alert alert-success" *ngIf="isSuccessful && !goalCreationFailed">
            Goal Updated!
          </div>
          <br>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
<div mat-dialog-actions>
  <button class="btn" (click)="onNoClick()">Cancel</button>
  <button mat-flat-button color="primary" [disabled]="isSuccessful" (click)="onSubmit()">Update Goal</button>
</div>
