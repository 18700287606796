export class Article {
  id: number;
  articleName: string;
  articleAuthor: string;
  articleSubject: string;
  articleType: string;
  visible: boolean;
  data: File;

  constructor(
    id: number,
    articleName: string,
    articleAuthor: string,
    articleSubject: string,
    articleType: string,
    isVisible: boolean,
    data: File

  ) {
    this.id = id;
    this.articleName = articleName;
    this.articleAuthor = articleAuthor;
    this.articleSubject = articleSubject;
    this.articleType = articleType;
    this.visible = isVisible;
    this.data = data;
  }
}
