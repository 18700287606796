<style>
  mat-card {
    max-width: 600px;
    margin: 2em auto;
    text-align: center;
    max-height: 600px;
  }

  .header {
    text-align: center;

  }

  .full-width {
    width: 80%;
  }

  .button-row {
    padding-top: 5px;
  }

  .button-row a {
    margin-right: 8px;
    text-align: center;
  }

  .forget-password {
    padding-left: 0px;
  }

  .emailInput {
    padding-top: 10px;
  }

  .contentBody {
    padding: 60px 1rem;
    background: #1b6ca8;
    display: block;
  }

  .aLink {
    float: right;
    padding-right: 60px;
    text-decoration: none;
  }

  .wide {
    width: 85% !important;
  }

</style>
<h1 mat-dialog-title>Add Food</h1>
<div mat-dialog-content>
  <mat-card>
    <mat-card-content>
        <p>
          <label class="btn btn-default">
            <input type="file" (change)="selectFile($event)"/>
          </label>
        </p>

        <div>


          <div class="alert alert-danger" *ngIf="submitted && foodSubmissionFailed">
            {{ errorMessage }}
          </div>
          <div class="alert alert-success" *ngIf="isSuccessful && !foodSubmissionFailed">
            Food File Uploaded!
          </div>
          <br>
        </div>
    </mat-card-content>
  </mat-card>
</div>

<div mat-dialog-actions>
  <button class="btn" (click)="onNoClick()">Cancel</button>
  <button mat-flat-button color="primary" [disabled]="isSuccessful || submitDisabled" (click)="onSubmit()">Upload Food
  </button>
</div>
