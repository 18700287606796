<mat-card>
  <mat-card-content>
    <div class="header">
      <h1>LOGIN</h1>
    </div>
    <form (keyup.enter)="onSubmit()" name="loginForm" [formGroup]="loginForm">
      <div class="emailInput">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Email</mat-label>
          <input
            formControlName="email"
            matInput
            placeholder="Enter email address" required
          />
          <ng-container
            *ngIf="!loginForm.controls['email'].valid && (loginForm.controls['email'].dirty || loginForm.controls['email'].touched)">
            <ng-container *ngIf="loginForm.controls['email'].errors.required">
              <mat-error>
                Email is required
              </mat-error>
            </ng-container>
            <ng-container *ngIf="loginForm.controls['email'].errors.email && !loginForm.controls['email'].errors.required">
              <mat-error>
                Invalid Email
              </mat-error>
            </ng-container>
          </ng-container>
        </mat-form-field>
      </div>

      <div>
          <span>
            <a class="text-link" class="aLink" routerLink="forgotPassword">Forgot Password?</a>
          </span>
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Password</mat-label>
          <input formControlName="password" matInput [type]=" hide ? 'password' : 'text'" required />
          <button  mat-icon-button matSuffix (click)="changeVisibility($event)" [attr.aria-label]="'Hide Password'"
                   [attr.aria-pressed]="hide">
            <mat-icon>
              {{hide ? 'visibility_off' : 'visibility'}}
            </mat-icon>
          </button>
          <mat-error *ngIf="!loginForm.controls['password'].valid">
            Password is required
          </mat-error>
        </mat-form-field>
        <div class="alert alert-warning" *ngIf="submitted && isLoginFailed">
          Invalid credentials!<br/>{{ errorMessage }}
        </div>

        <div class="alert alert-success" *ngIf="isLoggedIn">
          Logged in successfully!
        </div>
        <br>
      </div>
      <button class="wide" mat-flat-button color="primary" (click)="onSubmit()">Login</button>
    </form>
  </mat-card-content>
</mat-card>


