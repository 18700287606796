import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {TokenStorageService} from './token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuardService implements CanActivate {


  constructor(private router: Router,
              private tokenStorageService: TokenStorageService) {
  }

// tslint:disable-next-line:typedef
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.tokenStorageService.getUser().username === undefined) {
      return true;
    } else {
      this.router.navigate(['home']);
      return false;
    }

  }

}
