<h1 mat-dialog-title>Delete Activity</h1>
<div mat-dialog-content>
  <p>Are you sure you want to delete this activity?</p>
  <p>ID: <span style="font-weight: bold">{{data.id}}</span></p>
  <p>Activity: <span style="font-weight: bold">{{data.type}}</span></p>
  <br>
  <p>This action cannot be undone.</p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="data" (click)="confirm()" cdkFocusInitial>Confirm</button>
</div>


