import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.css']
})
export class UnauthorizedComponent implements OnInit {

  constructor(private location: Location, private router: Router, private titleService: Title) {
    this.titleService.setTitle('Unauthorized');
  }

  ngOnInit(): void {
  }

  goBack(): void {
    this.router.navigate(['home']);
    // this.location.back();
  }

}
