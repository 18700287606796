export class ActivityOption {
  type: string;

  intensity: number;

  coefficient: number;

  isVisible: boolean;

  id: number;

  constructor(
    type: string,
    intensity: number,
    coefficient: number,
    isVisible: boolean,
    id: number,
  ) {
    this.type = type;
    this.intensity = intensity;
    this.coefficient = coefficient;
    this.isVisible = isVisible;
    this.id = id;
  }
}
