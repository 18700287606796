  <div class="mat-elevation-z8">
    <h1>Goals</h1>
    <table mat-table [dataSource]="dataSource">

      <!-- Position Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell *matCellDef="let WeeklyGoal"> {{WeeklyGoal.id}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> Type </th>
        <td mat-cell *matCellDef="let WeeklyGoal"> {{WeeklyGoal.type}} </td>
      </ng-container>

       Weight Column
      <ng-container matColumnDef="goalDescription">
        <th mat-header-cell *matHeaderCellDef> Description </th>
        <td mat-cell *matCellDef="let WeeklyGoal"> {{WeeklyGoal.goalDescription}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="helpInfo">
        <th mat-header-cell *matHeaderCellDef> Help Info </th>
        <td mat-cell *matCellDef="let WeeklyGoal"> {{WeeklyGoal.help_info}} </td>
      </ng-container>

<!--      <ng-container matColumnDef="actions">-->
<!--        <th mat-header-cell *matHeaderCellDef> Actions</th>-->
<!--        <td mat-cell *matCellDef="let Food">-->
<!--          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">-->
<!--            <mat-icon>more_vert</mat-icon>-->
<!--          </button>-->
<!--        </td>-->
<!--      </ng-container>-->

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
<!--          <button mat-icon-button color="primary" (click)="openCreateDialog()">-->
<!--            <mat-icon aria-label="Example icon-button with a heart icon">add</mat-icon>-->
<!--          </button>-->
          <button mat-stroked-button (click)="openCreateDialog()">Add Goal</button>

        </th>
        <td mat-cell *matCellDef="let WeeklyGoal">
          <button mat-icon-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{WeeklyGoal: WeeklyGoal}" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
        </td>
      </ng-container>


      <mat-menu #menu="matMenu">
        <ng-template matMenuContent let-WeeklyGoal="WeeklyGoal">
          <button mat-menu-item (click)="openEditDialog(WeeklyGoal)">
            <mat-icon>edit</mat-icon>
            <span>Edit</span>
          </button>
          <button mat-menu-item>
            <mat-icon>delete</mat-icon>
            <span>Delete</span>
          </button>
        </ng-template>
      </mat-menu>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
      <mat-progress-spinner
        color="primary"
        mode="indeterminate">
      </mat-progress-spinner>
    </mat-card>

    <mat-paginator #paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="5" showFirstLastButtons></mat-paginator>

  </div>

