export class WeeklyGoal {
  type: string;

  goalDescription: string;

  help_info: string;

  id: number;

  constructor(
    type: string,
    goalDescription: string,
    helpInfo: string,
    id: number,
  ) {
    this.type = type;
    this.goalDescription = goalDescription;
    this.help_info = helpInfo;
    this.id = id;
  }
}
