import {Component, OnInit} from '@angular/core';
import {TokenStorageService} from '../_services/token-storage.service';
import {Title} from '@angular/platform-browser';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../_services/auth.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  currentUser: any;
  private roles: string[] = [];
  viewUserData = false;
  manageAppContent = false;
  manageAdministrators = false;
  passwordChangeSuccess = false;
  passwordChangeFailed = false;
  hideCurrent = true;
  hideNew = true;
  submitted = false;

  changePasswordForm: FormGroup = this.fb.group({
    currentPassword: ['', [Validators.required]],
    newPassword: ['', [Validators.required, Validators.minLength(6)]],
    confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
  }, {validator: this.checkIfMatchingPasswords('newPassword', 'confirmPassword'),});

  // tslint:disable-next-line:max-line-length
  constructor(private tokenStorageService: TokenStorageService, private titleService: Title, private fb: FormBuilder, private authService: AuthService) {
    this.titleService.setTitle('Profile');
  }

  ngOnInit(): void {
    this.currentUser = this.tokenStorageService.getUser();
    this.roles = this.currentUser.roles;
    this.viewUserData = this.roles.includes('ROLE_BASE');
    this.manageAppContent = this.roles.includes('ROLE_SUPER');
    this.manageAdministrators = this.roles.includes('ROLE_MASTER');
  }

  onSubmit(): void {
    this.submitted = true;
    if (!this.changePasswordForm.valid) {
      return;
    } else {
      const username = this.currentUser.email;
      const currentPassword = this.changePasswordForm.get('currentPassword').value;
      const newPassword = this.changePasswordForm.get('newPassword').value;
      this.authService.login(username, currentPassword).subscribe(
        data => {
          this.authService.changePassword(username, currentPassword, newPassword).subscribe(
            data2 => {
              // this.changePasswordForm.controls.currentPassword.setValue('');
              // this.changePasswordForm.controls.newPassword.setValue('');
              // this.changePasswordForm.controls.confirmPassword.setValue('');
              this.passwordChangeFailed = false;
              this.passwordChangeSuccess = true;
              setTimeout(() => window.location.reload(), 2000);
            },
            err => {
              console.log('err');
              console.log(err);
              this.passwordChangeFailed = true;
            }
          );

        }, error => {
          console.log('error');
          console.log(error);
          this.passwordChangeFailed = true;
        }
      );
    }
  }

  changeVisibilityCurrentPassword(event): void {
    if (event.clientX !== 0) {
      this.hideCurrent = !this.hideCurrent;
    }
  }

  changeVisibilityNewPassword(event): void {
    if (event.clientX !== 0) {
      this.hideNew = !this.hideNew;
    }
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string): any {
    return (group: FormGroup) => {
      const passwordInput = group.controls[passwordKey];
      const passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({notEquivalent: true});
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }
}
