<style>
  mat-card {
    max-width: 600px;
    margin: 2em auto;
    text-align: center;
    max-height: 600px;
  }

  .header {
    text-align: center;

  }

  .full-width {
    width: 80%;
  }

  .button-row {
    padding-top: 5px;
  }

  .button-row a {
    margin-right: 8px;
    text-align: center;
  }

  .forget-password {
    padding-left: 0px;
  }

  .emailInput {
    padding-top: 10px;
  }

  .contentBody {
    padding: 60px 1rem;
    background: #1b6ca8;
    display: block;
  }

  .aLink {
    float: right;
    padding-right: 60px;
    text-decoration: none;
  }

  .wide {
    width: 85% !important;
  }

</style>
<h1 mat-dialog-title>Edit Article</h1>

<div mat-dialog-content>
  <mat-card>
    <mat-card-content>
      <form (keyup.enter)="onSubmit()" name="articleForm" [formGroup]="articleForm">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Title</mat-label>
          <input
            [(ngModel)]="data.articleName"
            formControlName="articleName"
            matInput
            placeholder="Enter title" required
          />
          <ng-container
            *ngIf="!articleForm.controls['articleName'].valid && (articleForm.controls['articleName'].dirty || articleForm.controls['articleName'].touched)">
            <ng-container *ngIf="articleForm.controls['articleName'].errors.required">
              <mat-error>
                Title is required
              </mat-error>
            </ng-container>
          </ng-container>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Author</mat-label>
          <input
            [(ngModel)]="data.articleAuthor"
            formControlName="articleAuthor"
            matInput
            placeholder="Enter author" required
          />
          <ng-container
            *ngIf="!articleForm.controls['articleAuthor'].valid && (articleForm.controls['articleAuthor'].dirty || articleForm.controls['articleAuthor'].touched)">
            <ng-container *ngIf="articleForm.controls['articleAuthor'].errors.required">
              <mat-error>
                Author is required
              </mat-error>
            </ng-container>
          </ng-container>
        </mat-form-field>


        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Subject</mat-label>
          <mat-select [(ngModel)]="data.articleSubject"
                      formControlName="articleSubject">
            <mat-option value="Cancer Research">Cancer Research</mat-option>
            <mat-option value="Diet/Nutrition">Diet/Nutrition</mat-option>
            <mat-option value="Fitness/Exercise">Fitness/Exercise</mat-option>
            <mat-option value="Learning Activities">Learning Activities</mat-option>
            <mat-option value="Recipes">Recipes</mat-option>
            <mat-option value="Stress Management">Stress Management</mat-option>
            <mat-option value="Symptom Management">Symptom Management</mat-option>
            <mat-option value="Other">Other</mat-option>
          </mat-select>
          <ng-container
            *ngIf="!articleForm.controls['articleSubject'].valid && (articleForm.controls['articleSubject'].dirty || articleForm.controls['articleSubject'].touched)">
            <ng-container
              *ngIf="articleForm.controls['articleSubject'].errors.required">
              <mat-error>
                Subject must be selected
              </mat-error>
            </ng-container>
          </ng-container>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Type</mat-label>
          <mat-select [(ngModel)]="data.articleType"
                      formControlName="articleType">
            <mat-option value="Self-Help">Self-Help</mat-option>
            <mat-option value="Informative">Informative</mat-option>
          </mat-select>
          <ng-container
            *ngIf="!articleForm.controls['articleType'].valid && (articleForm.controls['articleType'].dirty || articleForm.controls['articleType'].touched)">
            <ng-container
              *ngIf="articleForm.controls['articleType'].errors.required">
              <mat-error>
                Type must be selected
              </mat-error>
            </ng-container>
          </ng-container>
        </mat-form-field>
        <div>


          <div class="alert alert-danger" *ngIf="submitted && articleCreationFailed">
            {{ errorMessage }}
          </div>
          <div class="alert alert-success" *ngIf="isSuccessful && !articleCreationFailed">
            Article Updated!
          </div>
          <br>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>

<div mat-dialog-actions>
  <button class="btn" (click)="onNoClick()">Cancel</button>
  <button mat-flat-button color="primary" [disabled]="isSuccessful" (click)="onSubmit()">Save
    Article
  </button>
</div>



