import { Injectable } from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpRequest} from '@angular/common/http';
import { Observable } from 'rxjs';

const AUTH_API = 'https://enact-crc-app.herokuapp.com/api/auth/';
// const AUTH_API = 'http://localhost:5000/api/auth/';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) { }

  login(username: string, password: string): Observable<any> {
    return this.http.post(AUTH_API + 'signin', {
      username,
      password
    }, httpOptions);
  }

  changePassword(email: string, currentPassword: string, newPassword: string): Observable<any>  {
    const data: FormData = new FormData();
    data.append('email', email);
    data.append('currentPassword', currentPassword);
    data.append('newPassword', newPassword);
    const newRequest = new HttpRequest('POST', AUTH_API + 'changePassword', data, {
      reportProgress: true,
      responseType: 'text'
    });
    return this.http.request(newRequest);
  }

  register(username: string, email: string, password: string): Observable<any> {
    return this.http.post(AUTH_API + 'signup', {
      username,
      email,
      password
    }, httpOptions);
  }
}
