<style>
  mat-card {
    max-width: 600px;
    margin: 2em auto;
    text-align: center;
    max-height: 600px;
  }

  .header {
    text-align: center;

  }

  .full-width {
    width: 80%;
  }

  .button-row {
    padding-top: 5px;
  }

  .button-row a {
    margin-right: 8px;
    text-align: center;
  }

  .forget-password {
    padding-left: 0px;
  }

  .emailInput {
    padding-top: 10px;
  }

  .contentBody {
    padding: 60px 1rem;
    background: #1b6ca8;
    display: block;
  }

  .aLink {
    float: right;
    padding-right: 60px;
    text-decoration: none;
  }

  .wide {
    width: 85% !important;
  }

</style>
<h1 mat-dialog-title>Add Video</h1>
<div mat-dialog-content>
  <mat-card>
    <mat-card-content>
      <form (keyup.enter)="onSubmit()" name="videoForm" [formGroup]="videoForm">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Title</mat-label>
          <input
            formControlName="videoName"
            matInput
            placeholder="Enter title" required
          />
          <ng-container
            *ngIf="!videoForm.controls['videoName'].valid && (videoForm.controls['videoName'].dirty || videoForm.controls['videoName'].touched)">
            <ng-container *ngIf="videoForm.controls['videoName'].errors.required">
              <mat-error>
                Title is required
              </mat-error>
            </ng-container>
          </ng-container>
        </mat-form-field>




        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Subject</mat-label>
          <mat-select formControlName="videoSubject">
            <mat-option value="Cancer Research">Cancer Research</mat-option>
            <mat-option value="Diet/Nutrition">Diet/Nutrition</mat-option>
            <mat-option value="Fitness/Exercise">Fitness/Exercise</mat-option>
            <mat-option value="Learning Activities">Learning Activities</mat-option>
            <mat-option value="Recipes">Recipes</mat-option>
            <mat-option value="Stress Management">Stress Management</mat-option>
            <mat-option value="Symptom Management">Symptom Management</mat-option>
            <mat-option value="Other">Other</mat-option>
          </mat-select>
          <ng-container
            *ngIf="!videoForm.controls['videoSubject'].valid && (videoForm.controls['videoSubject'].dirty || videoForm.controls['videoSubject'].touched)">
            <ng-container
              *ngIf="videoForm.controls['videoSubject'].errors.required">
              <mat-error>
                Subject must be selected
              </mat-error>
            </ng-container>
          </ng-container>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Type</mat-label>
          <mat-select formControlName="videoType">
            <mat-option value="Self-Help">Self-Help</mat-option>
            <mat-option value="Informative">Informative</mat-option>
          </mat-select>
          <ng-container
            *ngIf="!videoForm.controls['videoType'].valid && (videoForm.controls['videoType'].dirty || videoForm.controls['videoType'].touched)">
            <ng-container
              *ngIf="videoForm.controls['videoType'].errors.required">
              <mat-error>
                Type must be selected
              </mat-error>
            </ng-container>
          </ng-container>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>URL</mat-label>
          <input
            formControlName="videoUrl"
            matInput
            placeholder="Enter URL" required
          />
          <ng-container
            *ngIf="!videoForm.controls['videoUrl'].valid && (videoForm.controls['videoUrl'].dirty || videoForm.controls['videoUrl'].touched)">
            <ng-container *ngIf="videoForm.controls['videoUrl'].errors.required">
              <mat-error>
                URL is required
              </mat-error>
            </ng-container>
            <ng-container *ngIf="!videoForm.controls['videoUrl'].errors.required && videoForm.controls['videoUrl'].errors.pattern">
              <mat-error>
                Not a valid URL
              </mat-error>
            </ng-container>
          </ng-container>
        </mat-form-field>

        <div>


          <div class="alert alert-danger" *ngIf="submitted && activityCreationFailed">
            {{ errorMessage }}
          </div>
          <div class="alert alert-success" *ngIf="isSuccessful && !activityCreationFailed">
            Video Created!
          </div>
          <br>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>

<div mat-dialog-actions>
  <button class="btn" (click)="onNoClick()">Cancel</button>
  <button mat-flat-button color="primary" [disabled]="isSuccessful" (click)="onSubmit()">Save
    Video
  </button>
</div>
