<!--<ng-container *ngIf="isLoading">-->

<!--  <mat-spinner></mat-spinner>-->

<!--</ng-container>-->

<!--<ng-container *ngIf="!isLoading">-->
<div class="mat-elevation-z8">
  <h1>Articles</h1>
  <table mat-table [dataSource]="dataSource">

    <!-- Position Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID</th>
      <td mat-cell *matCellDef="let Article"> {{Article.id}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef> Title</th>
      <td mat-cell *matCellDef="let Article"> {{Article.articleName}} </td>
    </ng-container>

    <ng-container matColumnDef="author">
      <th mat-header-cell *matHeaderCellDef> Author</th>
      <td mat-cell *matCellDef="let Article"> {{Article.articleAuthor}} </td>
    </ng-container>

    <ng-container matColumnDef="subject">
      <th mat-header-cell *matHeaderCellDef> Subject</th>
      <td mat-cell *matCellDef="let Article"> {{Article.articleSubject}} </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef> Type</th>
      <td mat-cell *matCellDef="let Article"> {{Article.articleType}} </td>
    </ng-container>

<!--    <ng-container matColumnDef="isVisible">-->
<!--      <th mat-header-cell *matHeaderCellDef> Visible</th>-->
<!--      <td mat-cell *matCellDef="let Article"> {{Article.visible}} </td>-->
<!--    </ng-container>-->

    <!--      <ng-container matColumnDef="actions">-->
    <!--        <th mat-header-cell *matHeaderCellDef> Actions</th>-->
    <!--        <td mat-cell *matCellDef="let Food">-->
    <!--          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">-->
    <!--            <mat-icon>more_vert</mat-icon>-->
    <!--          </button>-->
    <!--        </td>-->
    <!--      </ng-container>-->

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
<!--        <button mat-icon-button color="primary" (click)="openCreateDialog()">-->
<!--          <mat-icon aria-label="Example icon-button with a heart icon">add</mat-icon>-->
<!--        </button>-->
        <button mat-stroked-button (click)="openCreateDialog()">Add Article</button>
      </th>
      <td mat-cell *matCellDef="let Article">
        <button (click)="setDownloadUrl(Article.data)" mat-icon-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{Article: Article}" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
      </td>
    </ng-container>


    <mat-menu #menu="matMenu">
      <ng-template matMenuContent let-Article="Article">
        <button mat-menu-item (click)="openEditDialog(Article)">
          <mat-icon>edit</mat-icon>
          <span>Edit</span>
        </button>
        <button mat-menu-item (click)="openDeleteDialog(Article)">
          <mat-icon>delete</mat-icon>
          <span>Delete</span>
        </button>
        <button mat-menu-item (click)="viewArticle(Article.data)">
          <mat-icon>visibility</mat-icon>
          <span>View PDF</span>
        </button>


        <button mat-menu-item>
          <mat-icon>file_download</mat-icon>
<!--          <span>Download PDF</span>-->
          <a style="color: inherit" [href]="fileUrl" download="{{Article.articleName}}.pdf"><span>Download PDF</span></a>
        </button>
      </ng-template>
    </mat-menu>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <button mat-icon-button aria-label="Example icon button with a vertical three dot icon">
      <mat-icon>more_vert</mat-icon>
    </button>
  </table>
  <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
    <mat-progress-spinner
      color="primary"
      mode="indeterminate">
    </mat-progress-spinner>
  </mat-card>
  <!--      <mat-paginator [pageSize]="10" showFirstLastButtons></mat-paginator>-->
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

</div>


<!--</ng-container>-->

<!--<label class="btn btn-default">-->
<!--  <input type="file" (change)="selectFile($event)" />-->
<!--</label>-->

<!--<button class="btn btn-success" [disabled]="!selectedFiles" (click)="upload()">-->
<!--  Upload-->
<!--</button>-->

<!--<label class="btn btn-default">-->
<!--  <input type="file" (change)="selectFile($event)" />-->
<!--</label>-->
<!--<button class="btn btn-primary"  (click)="upload()">Save</button>-->
