import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../_services/user.service';
import {Food} from '../models/Food';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import {WeeklyGoal} from '../models/WeeklyGoal';
import {AddGoalDialogComponent} from '../weekly_goals/weekly_goals.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Title} from '@angular/platform-browser';
import {Article} from '../models/Article';
import {AddArticleDialogComponent} from '../articles/articles.component';

@Component({
  selector: 'app-food-view',
  templateUrl: './food_view.component.html',
  styleUrls: ['./food_view.component.css']
})
export class FoodViewComponent implements OnInit {
  content?: string;

  foods: Food[] = [];
  displayedColumns: string[] = ['id', 'baseId', 'description', 'actions'];

  dataSource: any;
  file: File;
  submitDisabled = true;

  title = 'File-Upload-Save';
  selectedFiles: FileList;
  currentFileUpload: File;
  selectedFile = null;
  changeImage = false;
  isLoading = false;


  @ViewChild(MatPaginator) paginator: MatPaginator;

  // tslint:disable-next-line:max-line-length
  constructor(private userService: UserService, public createDialog: MatDialog, private snackBar: MatSnackBar, private titleService: Title) {
    this.titleService.setTitle('Foods');
  }

  ngOnInit(): void {
    this.getFood();
  }

  getFood(): void {
    this.isLoading = true;
    this.userService.getFood().subscribe(foods => {
      this.foods = [];
      for (const val of foods) {
        const food = new Food(val.id, val.baseId, val.description);
        this.foods.push(food);
      }
      this.dataSource = new MatTableDataSource<Food>(this.foods);
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    });
  }

  // tslint:disable-next-line:typedef
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }


  // upload(): void {
  //   this.currentFileUpload = this.file;
  //   this.isLoading = true;
  //   this.userService.pushFileToStorage(this.file).subscribe(event => {
  //       this.selectedFiles = undefined;
  //       this.getFood();
  //       // window.location.reload();
  //     }
  //   );
  //   this.isLoading = true;
  //   setTimeout(() => {
  //     console.log('sleep');
  //     // this.getGoals();
  //     this.isLoading = false;
  //     // window.location.reload();
  //     this.getFood();
  //     window.location.reload();
  //     // And any other code that should run only after 5s
  //   }, 2000);
  //
  // }

  // selectFile(event): void {
  //   if (event.target.files.length === 1) {
  //     const file = event.target.files[0];
  //     this.file = file;
  //     this.submitDisabled = false;
  //     console.log(file);
  //   } else {
  //     this.submitDisabled = true;
  //   }
  //   this.selectedFiles = event.target.files;
  // }

  // openCreateDialog(): void {
  //   const dialogRef = this.createDialog.open(AddFoodDialogComponent, {
  //     width: '600px',
  //     height: '250px',
  //     data: this.file
  //   });
  //   // this.createDialog.open(AddActivityDialogComponent);
  //
  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed');
  //     console.log(result);
  //     // // If all are valid save
  //     if (result) {
  //       this.file = result;
  //       console.log('valid');
  //       this.userService.pushFileToStorage(this.file).subscribe(event => {
  //         this.openSnackBar('File Uploaded Successfully', 'Close');
  //         this.isLoading = true;
  //         this.file = undefined;
  //         this.selectedFiles = undefined;
  //         this.getFood();
  //           // window.location.reload();
  //         }
  //       );
  //     } else {
  //       this.openSnackBar('Error Uploading File', 'Close');
  //       console.log('invalid');
  //     }
  //   });
  // }

  openCreateDialog(): void {
    const dialogRef = this.createDialog.open(AddFoodDialogComponent, {
      width: '600px',
      height: '350px',
      data: this.file
    });
    // this.createDialog.open(AddActivityDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.openSnackBar('File Uploaded Successfully', 'Close');
        this.getFood();
      }
    });
  }

}

@Component({
  selector: 'app-add-food-dialog',
  templateUrl: 'app-add-food-dialog.html',
})
export class AddFoodDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AddFoodDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddDialogData, private userService: UserService) {
    this.dialogRef.backdropClick().subscribe(result => {
      dialogRef.close(this.isSuccessful);
    });
  }
  file: File;
  submitDisabled = true;
  submitted = false;
  isSuccessful = false;
  foodSubmissionFailed = false;
  isLoading = false;
  errorMessage = '';

  selectFile(event): void {
    if (event.target.files.length === 1) {
      const file = event.target.files[0];
      this.data = file;
      this.file = file;
      this.submitDisabled = false;
    } else {
      this.submitDisabled = true;
    }
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.file.type !== 'application/vnd.ms-excel') {
      this.errorMessage = 'Error: Invalid file type. File must be a CSV.';
      this.foodSubmissionFailed = true;
    } else {
      // tslint:disable-next-line:max-line-length
      this.userService.pushFileToStorage(this.file).subscribe(event => {
          this.foodSubmissionFailed = false;
          this.isSuccessful = true;
          this.isLoading = true;
          this.file = undefined;
        }, err => {
          this.errorMessage = err.error.message;
          this.errorMessage = 'Error: Could not upload food';
          this.foodSubmissionFailed = true;
        }
      );
    }
  }


  onNoClick(): void {
    this.dialogRef.close(this.isSuccessful);
  }
}

export interface AddDialogData {
  file: File;
}

