import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {UserService} from '../_services/user.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {Article} from '../models/Article';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.css']
})
export class ArticlesComponent implements OnInit {


  articles: Article[] = [];
  article: Article;
  displayedColumns: string[] = ['id', 'title', 'author', 'subject', 'type', 'actions'];

  dataSource: any;
  file: File;
  submitDisabled = true;

  title = 'File-Upload-Save';
  selectedFiles: FileList;
  currentFileUpload: File;
  selectedFile = null;
  changeImage = false;
  isLoading = false;
  fileUrl;


  @ViewChild(MatPaginator) paginator: MatPaginator;

  // tslint:disable-next-line:max-line-length
  constructor(private userService: UserService, public createDialog: MatDialog, private snackBar: MatSnackBar, private sanitizer: DomSanitizer, private titleService: Title) {
    this.titleService.setTitle('Articles');
  }

  ngOnInit(): void {
    this.getArticles();
  }

  getArticles(): void {
    this.isLoading = true;
    this.userService.getArticles().subscribe(articles => {
      this.articles = [];
      for (const val of articles) {
        // tslint:disable-next-line:max-line-length
        const article = new Article(val.id, val.articleName, val.articleAuthor, val.articleSubject, val.articleType, val.visible, val.data);
        this.articles.push(article);
      }
      this.dataSource = new MatTableDataSource<Article>(this.articles);
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    });
  }


  // tslint:disable-next-line:typedef
  viewArticle(data) {
    const byteArray = new Uint8Array(atob(data).split('').map(char => char.charCodeAt(0)));
    const blob = new Blob([byteArray], {type: 'application/pdf'});
    const fileURL = URL.createObjectURL(blob);
    window.open(fileURL, '_blank');
  }

  setDownloadUrl(data): void {
    const byteArray = new Uint8Array(atob(data).split('').map(char => char.charCodeAt(0)));
    const blob = new Blob([byteArray], {type: 'application/pdf'});
    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
  }


  selectFile(event): void {
    if (event.target.files.length === 1) {
      const file = event.target.files[0];
      this.file = file;
      this.submitDisabled = false;
    } else {
      this.submitDisabled = true;
    }
    this.selectedFiles = event.target.files;
  }

  // upload(): void {
  //   this.currentFileUpload = this.file;
  //   this.isLoading = true;
  //   this.userService.pushPdfToStorage(this.file, 'testtitle', 'testauthor', 'testtopic').subscribe(event => {
  //       this.selectedFiles = undefined;
  //       // window.location.reload();
  //     }
  //   );
  // }

  openCreateDialog(): void {
    const newArticle = new Article(0, '', '', '', '', true, undefined);
    const dialogRef = this.createDialog.open(AddArticleDialogComponent, {
      width: '600px',
      height: '650px',
      data: newArticle
    });
    // this.createDialog.open(AddActivityDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.openSnackBar('Article Created Successfully', 'Close');
        this.getArticles();
      }
    });
  }

  openEditDialog(article): void {
    const dialogRef = this.createDialog.open(EditArticleDialogComponent, {
      width: '600px',
      height: '560px',
      data: article
    });
    // this.createDialog.open(AddActivityDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.openSnackBar('Article Updated Successfully', 'Close');
        this.getArticles();
      }
    });
  }

  // tslint:disable-next-line:typedef
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  openDeleteDialog(article): void {
    const dialogRef = this.createDialog.open(DeleteArticleDialogComponent, {
      width: '600px',
      height: '300px',
      data: article
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.article == null) {
          this.userService.deleteArticle(article.id).subscribe(event => {
              this.isLoading = true;
              this.openSnackBar('Article Deleted Successfully', 'Close');
              this.getArticles();
            }
          );
        }
      }
    });
  }

}

@Component({
  selector: 'app-add-article-dialog',
  templateUrl: 'app-add-article-dialog.html',
})
export class AddArticleDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AddArticleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddDialogData, private fb: FormBuilder, private userService: UserService) {
    this.dialogRef.backdropClick().subscribe(result => {
      dialogRef.close(this.isSuccessful);
    });
  }

  file: File;
  submitDisabled = true;
  isLoading = false;
  articleName: string;
  articleAuthor: string;
  articleSubject: string;
  articleType: string;
  visible = true;

  username: string;
  isSuccessful = false;
  activityCreationFailed = false;
  errorMessage = '';
  submitted = false;
  articleForm: FormGroup = this.fb.group({
    articleName: ['', [Validators.required]],
    articleAuthor: ['', [Validators.required]],
    articleSubject: ['', [Validators.required]],
    articleType: ['', [Validators.required]],
  });


  selectFile(event): void {
    if (event.target.files.length === 1) {
      const file = event.target.files[0];
      this.file = file;
      // tslint:disable-next-line:max-line-length
      const newArticle = new Article(0, this.articleName, this.articleAuthor, this.articleSubject, this.articleType, this.visible, this.file);
      this.data.article = newArticle;
      this.submitDisabled = false;
    } else {
      this.submitDisabled = true;
    }
  }


  onNoClick(): void {
    this.dialogRef.close(this.isSuccessful);
  }

  onSubmit(): void {
    this.submitted = true;
    if (!this.articleForm.valid) {
      return;
    } else {
      const articleName = this.articleForm.get('articleName').value;
      const articleAuthor = this.articleForm.get('articleAuthor').value;
      const articleSubject = this.articleForm.get('articleSubject').value;
      const articleType = this.articleForm.get('articleType').value;

      if (this.file.type !== 'application/pdf') {
        this.errorMessage = 'Error: Invalid file type. File must be a PDF.';
        this.activityCreationFailed = true;
      } else {
        // tslint:disable-next-line:max-line-length
        this.userService.pushPdfToStorage(this.file, articleName, articleAuthor, articleSubject, articleType, true).subscribe(data => {
            this.activityCreationFailed = false;
            this.isSuccessful = true;
            this.isLoading = true;
            this.file = undefined;
          }, err => {
            this.errorMessage = err.error.message;
            this.errorMessage = 'Error: Could not create article';
            this.activityCreationFailed = true;
          }
        );
      }
    }
  }
}

export interface AddDialogData {
  article: Article;
}

@Component({
  selector: 'app-edit-article-dialog',
  templateUrl: 'app-edit-article-dialog.html',
})
export class EditArticleDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<EditArticleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditDialogData, private fb: FormBuilder, private userService: UserService) {
    this.dialogRef.backdropClick().subscribe(result => {
      dialogRef.close(this.isSuccessful);
    });
  }

  file: File;
  isLoading = false;
  articleName: string;
  articleAuthor: string;
  articleSubject: string;
  articleType: string;
  visible: boolean;

  username: string;
  isSuccessful = false;
  articleCreationFailed = false;
  errorMessage = '';
  submitted = false;
  articleForm: FormGroup = this.fb.group({
    articleName: ['', [Validators.required]],
    articleAuthor: ['', [Validators.required]],
    articleSubject: ['', [Validators.required]],
    articleType: ['', [Validators.required]],
  });

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.submitted = true;
    if (!this.articleForm.valid) {
      return;
    } else {
      const articleName = this.articleForm.get('articleName').value;
      const articleAuthor = this.articleForm.get('articleAuthor').value;
      const articleSubject = this.articleForm.get('articleSubject').value;
      const articleType = this.articleForm.get('articleType').value;
      const newArticle = new Article(this.data.id, articleName, articleAuthor, articleSubject, articleType, true, null);
      this.userService.editPdfInStorage(this.data.id, newArticle).subscribe(data => {
          this.articleCreationFailed = false;
          this.isSuccessful = true;
          this.isLoading = true;
          this.file = undefined;
        }, err => {
          this.errorMessage = err.error.message;
          this.errorMessage = 'Error: Could not create article';
          this.articleCreationFailed = true;
        }
      );
    }
  }
}

export interface EditDialogData {
  article: Article;
  articleName: any;
  articleAuthor: any;
  articleSubject: any;
  articleType: any;
  visible: any;
  id: any;
}

@Component({
  selector: 'app-delete-article-dialog',
  templateUrl: 'app-delete-article-dialog.html',
})
export class DeleteArticleDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DeleteArticleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditDialogData) {
  }

  confirm(): void {
    this.data.article = null;
    // this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

export interface EditDialogData {
  article: Article;
  articleName: any;
  articleAuthor: any;
  articleSubject: any;
  articleType: any;
  visible: any;
}


