import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../_services/user.service';
import {ActivityOption} from '../models/ActivityOption';
import {WeeklyGoal} from '../models/WeeklyGoal';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Article} from '../models/Article';
import {DeleteArticleDialogComponent} from '../articles/articles.component';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-board-user',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.css']
})
export class ActivitiesComponent implements OnInit {
  content?: string;

  activityOptions: ActivityOption[] = [];
  activityOption: ActivityOption;
  // displayedColumns: string[] = ['id', 'type', 'intensity', 'coefficient', 'isVisible', 'actions'];
  displayedColumns: string[] = ['id', 'type', 'intensity', 'coefficient', 'actions'];

  dataSource: any;
  form: FormGroup;
  isLoading = false;


  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  // tslint:disable-next-line:max-line-length
  constructor(private userService: UserService, public createDialog: MatDialog, private snackBar: MatSnackBar, private titleService: Title) {
    this.titleService.setTitle('Activities');
  }

  ngOnInit(): void {
    this.getActivities();
  }

  getActivities(): void {
    this.isLoading = true;
    this.userService.getActivityOptions().subscribe(activities => {
      this.activityOptions = [];
      for (const val of activities) {
        if (val.isVisible) {
          const activityOption = new ActivityOption(val.type, val.intensity, val.coefficient, val.isVisible, val.id);
          this.activityOptions.push(activityOption);
        }
      }
      this.dataSource = new MatTableDataSource(this.activityOptions);
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    });
  }
  //
  // test(x): void {
  //   console.log(x);
  // }

  openCreateDialog(): void {
    this.activityOption = new ActivityOption('', 1, 0.1, true, 0);
    const dialogRef = this.createDialog.open(AddActivityDialogComponent, {
      width: '600px',
      data: this.activityOption
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.openSnackBar('Activity Created', 'Close');
        this.getActivities();
      }
    });
  }

  openEditDialog(activityOption): void {
    const dialogRef = this.createDialog.open(EditActivityDialogComponent, {
      width: '600px',
      data: activityOption
    });
    // this.createDialog.open(AddActivityDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.openSnackBar('Activity Updated', 'Close');
        this.getActivities();
      }
    });
  }

  openDeleteDialog(activityOption): void {
    const dialogRef = this.createDialog.open(DeleteActivityDialogComponent, {
      width: '375px',
      height: '300px',
      data: activityOption
    });
    // this.createDialog.open(AddActivityDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.activity == null) {
          // tslint:disable-next-line:max-line-length
          const updatedActivityOption: ActivityOption = new ActivityOption(activityOption.type, activityOption.intensity, activityOption.coefficient, false, activityOption.id);
          this.userService.editActivity(updatedActivityOption).subscribe(event => {
              this.isLoading = true;
              this.openSnackBar('Activity Deleted Successfully', 'Close');
              this.getActivities();
            }
          );
        }
      } else {
        this.openSnackBar('Error Deleting Activity', 'Close');
      }
    });
  }

  // tslint:disable-next-line:typedef
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }


}

@Component({
  selector: 'app-add-activity-dialog',
  templateUrl: 'app-add-activity-dialog.html',
})
export class AddActivityDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AddActivityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddDialogData, private fb: FormBuilder, private userService: UserService) {
    this.dialogRef.backdropClick().subscribe(result => {
      dialogRef.close(this.isSuccessful);
    });
  }
  username: string;
  isSuccessful = false;
  activityCreationFailed = false;
  errorMessage = '';
  submitted = false;
  activityForm: FormGroup = this.fb.group({
    type: ['', [Validators.required]],
    intensity: ['', [Validators.required]],
    coefficient: ['', [Validators.required, Validators.pattern('^[.0-9]*$')]]
  });

  onNoClick(): void {
    this.dialogRef.close(this.isSuccessful);
  }

  onSubmit(): void {
    this.submitted = true;
    if (!this.activityForm.valid) {
      return;
    } else {
      const type = this.activityForm.get('type').value;
      const intensity = this.activityForm.get('intensity').value;
      const coefficient = this.activityForm.get('coefficient').value;

      const activityOption: ActivityOption = new ActivityOption(type, +intensity, +coefficient, true, 0);
      this.userService.createActivity(activityOption).subscribe(data => {
        this.isSuccessful = true;
        this.activityCreationFailed = false;
      }, err => {
        this.errorMessage = err.error.message;
        this.errorMessage = 'Error: Could not create activity';
        this.activityCreationFailed = true;
      });
    }
  }
}

@Component({
  selector: 'app-edit-activity-dialog',
  templateUrl: 'app-edit-activity-dialog.html',
})
export class EditActivityDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<EditActivityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditDialogData, private fb: FormBuilder, private userService: UserService) {
  }
  username: string;
  isSuccessful = false;
  activityCreationFailed = false;
  errorMessage = '';
  submitted = false;
  activityForm: FormGroup = this.fb.group({
    type: ['', [Validators.required]],
    intensity: ['', [Validators.required]],
    coefficient: ['', [Validators.required, Validators.pattern('^[.0-9]*$')]]
  });

  onNoClick(): void {
    this.dialogRef.close(this.isSuccessful);
  }

  onSubmit(): void {
    this.submitted = true;
    if (!this.activityForm.valid) {
      return;
    } else {
      const activity = this.activityForm.get('type').value;
      const intensity = this.activityForm.get('intensity').value;
      const coefficient = this.activityForm.get('coefficient').value;

      const activityOption: ActivityOption = new ActivityOption(activity, +intensity, +coefficient, true, this.data.id);
      this.userService.editActivity(activityOption).subscribe(data => {
        this.isSuccessful = true;
        this.activityCreationFailed = false;
      }, err => {
        this.errorMessage = err.error.message;
        this.errorMessage = 'Error: Could not create activity';
        this.activityCreationFailed = true;
      });
    }
  }
}



export interface AddDialogData {
  activity: string;
  intensity: number;
  coefficient: number;
  isVisible: boolean;
}

export interface EditDialogData {
  id: number;
  type: string;
  intensity: number;
  coefficient: number;
  isVisible: boolean;
}

@Component({
  selector: 'app-delete-activity-dialog',
  templateUrl: 'app-delete-activity-dialog.html',
})
export class DeleteActivityDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DeleteActivityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteDialogData) {
  }

  confirm(): void {
    this.data.activity = null;
    // this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

export interface DeleteDialogData {
  activity: ActivityOption;
  id: any;
  type: any;
}

