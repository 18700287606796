<style>
  mat-card {
    max-width: 600px;
    margin: 2em auto;
    text-align: center;
    max-height: 600px;
  }

  .header {
    text-align: center;

  }

  .full-width {
    width: 80%;
  }

  .button-row {
    padding-top: 5px;
  }

  .button-row a {
    margin-right: 8px;
    text-align: center;
  }

  .forget-password {
    padding-left: 0px;
  }

  .emailInput {
    padding-top: 10px;
  }

  .contentBody {
    padding: 60px 1rem;
    background: #1b6ca8;
    display: block;
  }

  .aLink {
    float: right;
    padding-right: 60px;
    text-decoration: none;
  }

  .wide {
    width: 85% !important;
  }

</style>
<h1 mat-dialog-title>Edit Activity</h1>
<div mat-dialog-content>
  <mat-card>
    <mat-card-content>
      <form (keyup.enter)="onSubmit()" name="activityForm" [formGroup]="activityForm">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Activity</mat-label>
          <input
            formControlName="type"
            matInput
            placeholder="Enter activity" required
            [(ngModel)]="data.type"
          />
          <ng-container
            *ngIf="!activityForm.controls['type'].valid && (activityForm.controls['type'].dirty || activityForm.controls['type'].touched)">
            <ng-container *ngIf="activityForm.controls['type'].errors.required">
              <mat-error>
                Activity is required
              </mat-error>
            </ng-container>
          </ng-container>
        </mat-form-field>


        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Intensity</mat-label>
          <mat-select [(ngModel)]="data.intensity" formControlName="intensity">
            <mat-option [value]="1">1</mat-option>
            <mat-option [value]="2">2</mat-option>
            <mat-option [value]="3">3</mat-option>
          </mat-select>
          <ng-container
            *ngIf="!activityForm.controls['intensity'].valid && (activityForm.controls['intensity'].dirty || activityForm.controls['intensity'].touched)">
            <ng-container
              *ngIf="activityForm.controls['intensity'].errors.required">
              <mat-error>
                Intensity must be selected
              </mat-error>
            </ng-container>
          </ng-container>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Coefficient</mat-label>
          <input
            formControlName="coefficient"
            matInput
            placeholder="Enter coefficient" required
            [(ngModel)]="data.coefficient"
          />
          <ng-container
            *ngIf="!activityForm.controls['coefficient'].valid && (activityForm.controls['coefficient'].dirty || activityForm.controls['coefficient'].touched)">
            <ng-container *ngIf="activityForm.controls['coefficient'].errors.required">
              <mat-error>
                Coefficient is required
              </mat-error>
            </ng-container>
            <ng-container
              *ngIf="activityForm.controls['coefficient'].errors.pattern && !activityForm.controls['coefficient'].errors.required">
              <mat-error>
                Coefficient must be a number
              </mat-error>
            </ng-container>
          </ng-container>
        </mat-form-field>



        <div>


          <div class="alert alert-danger" *ngIf="submitted && activityCreationFailed">
            {{ errorMessage }}
          </div>
          <div class="alert alert-success" *ngIf="isSuccessful && !activityCreationFailed">
            Activity Updated!
          </div>
          <br>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>

<div mat-dialog-actions>
  <button class="btn" (click)="onNoClick()">Cancel</button>
  <button mat-flat-button color="primary" [disabled]="isSuccessful" (click)="onSubmit()">Update Activity</button>
</div>
