  <div class="mat-elevation-z8">
    <h1 >Activities</h1>
<!--    <button mat-raised-button (click)="openDialog()">Add Activity</button>-->
    <table mat-table [dataSource]="dataSource">

      <!-- Position Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell *matCellDef="let ActivityOption"> {{ActivityOption.id}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> Activity </th>
        <td mat-cell *matCellDef="let ActivityOption"> {{ActivityOption.type}} </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="intensity">
        <th mat-header-cell *matHeaderCellDef> Intensity </th>
        <td mat-cell *matCellDef="let ActivityOption"> {{ActivityOption.intensity}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="coefficient">
        <th mat-header-cell *matHeaderCellDef> Coefficient </th>
        <td mat-cell *matCellDef="let ActivityOption"> {{ActivityOption.coefficient}} </td>
      </ng-container>

<!--      &lt;!&ndash; Symbol Column &ndash;&gt;-->
<!--      <ng-container matColumnDef="isVisible">-->
<!--        <th mat-header-cell *matHeaderCellDef> Visible </th>-->
<!--        <td mat-cell *matCellDef="let ActivityOption"> {{ActivityOption.isVisible}} </td>-->
<!--      </ng-container>-->

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
          <button mat-stroked-button (click)="openCreateDialog()">Add Activity</button>

<!--          <button mat-icon-button color="primary" (click)="openCreateDialog()">-->
<!--            <mat-icon aria-label="Example icon-button with a heart icon">add</mat-icon>-->
<!--          </button>-->
        </th>
        <td mat-cell *matCellDef="let ActivityOption">
          <button mat-icon-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{ActivityOption: ActivityOption}" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- actions -->
<!--      <ng-container matColumnDef="actions">-->
        <mat-header-cell *matHeaderCellDef>
          <button mat-icon-button color="primary" (click)="openCreateDialog()">
            <mat-icon aria-label="Example icon-button with a heart icon">add</mat-icon>
          </button>
        </mat-header-cell>

      <mat-menu #menu="matMenu">
        <ng-template matMenuContent let-ActivityOption="ActivityOption">
          <button mat-menu-item (click)="openEditDialog(ActivityOption)">
            <mat-icon>edit</mat-icon>
            <span>Edit</span>
          </button>
          <button mat-menu-item (click)="openDeleteDialog(ActivityOption)">
            <mat-icon>delete</mat-icon>
            <span>Delete</span>
          </button>
        </ng-template>
      </mat-menu>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
      <mat-progress-spinner
        color="primary"
        mode="indeterminate">
      </mat-progress-spinner>
    </mat-card>

    <mat-paginator #paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="5" showFirstLastButtons></mat-paginator>

  </div>

