<ng-container *ngIf="isLoading">

  <mat-spinner></mat-spinner>

</ng-container>

<ng-container *ngIf="!isLoading">
  <div class="mat-elevation-z8">
<h1>Users</h1>
    <table mat-table [dataSource]="dataSource">

      <!-- Position Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID</th>
        <td mat-cell *matCellDef="let User"> {{User.id}} </td>
      </ng-container>

      <!-- Get Details -->
      <ng-container matColumnDef="getdetails">
        <th mat-header-cell *matHeaderCellDef> Details </th>
        <td mat-cell *matCellDef="let User"> <button mat-flat-button color="primary" (click)="getDetails(User.id)">Get Details</button> </td>
      </ng-container>

<!--      <ng-container matColumnDef="actions">-->
<!--        <th mat-header-cell *matHeaderCellDef> Actions</th>-->
<!--        <td mat-cell *matCellDef="let Food">-->
<!--          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">-->
<!--            <mat-icon>more_vert</mat-icon>-->
<!--          </button>-->
<!--        </td>-->
<!--      </ng-container>-->
<!--      <mat-menu #menu="matMenu">-->
<!--        <button mat-menu-item>-->
<!--          <mat-icon>edit</mat-icon>-->
<!--          <span>Edit</span>-->
<!--        </button>-->
<!--        <button mat-menu-item>-->
<!--          <mat-icon>delete</mat-icon>-->
<!--          <span>Delete</span>-->
<!--        </button>-->
<!--      </mat-menu>-->


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <button mat-icon-button aria-label="Example icon button with a vertical three dot icon">
        <mat-icon>more_vert</mat-icon>
      </button>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

  </div>
</ng-container>

