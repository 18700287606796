import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../_services/user.service';
import {WeeklyGoal} from '../models/WeeklyGoal';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {ActivityOption} from '../models/ActivityOption';
import {AddActivityDialogComponent, EditActivityDialogComponent} from '../activities/activities.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-weekly-goal',
  templateUrl: './weekly_goals.component.html',
  styleUrls: ['./weekly_goals.component.css']
})
export class WeeklyGoalsComponent implements OnInit {
  content?: string;

  weeklyGoals: WeeklyGoal[] = [];
  weeklyGoal: WeeklyGoal;
  displayedColumns: string[] = ['id', 'type', 'helpInfo', 'goalDescription', 'actions'];

  dataSource: any;
  isLoading = false;


  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  // tslint:disable-next-line:max-line-length
  constructor(private userService: UserService, public createDialog: MatDialog, private snackBar: MatSnackBar, private titleService: Title) {
    this.titleService.setTitle('Weekly Goals');
  }

  ngOnInit(): void {
    this.getGoals();
  }

  getGoals(): void {
    this.isLoading = true;
    this.userService.getWeeklyGoals().subscribe(goals => {
      this.weeklyGoals = [];
      for (const val of goals) {
        const weeklyGoal = new WeeklyGoal(val.type, val.goalDescription, val.help_info, val.id);
        this.weeklyGoals.push(weeklyGoal);
      }
      this.dataSource = new MatTableDataSource<WeeklyGoal>(this.weeklyGoals);
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    });
  }


  openCreateDialog(): void {
    this.weeklyGoal = new WeeklyGoal('', '', '', 0);
    const dialogRef = this.createDialog.open(AddGoalDialogComponent, {
      width: '600px',
      data: this.weeklyGoal
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.openSnackBar('Goal Created', 'Close');
        this.getGoals();
      }
    });
  }

  // tslint:disable-next-line:typedef
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  openEditDialog(weeklyGoal): void {
    const dialogRef = this.createDialog.open(EditGoalDialogComponent, {
      width: '600px',
      data: weeklyGoal
    });
    // this.createDialog.open(AddActivityDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.openSnackBar('Goal Updated', 'Close');
        this.getGoals();
      }
    });
  }
}


@Component({
  selector: 'app-add-goal-dialog',
  templateUrl: 'app-add-goal-dialog.html',
})
export class AddGoalDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AddGoalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddDialogData, private fb: FormBuilder, private userService: UserService) {
    this.dialogRef.backdropClick().subscribe(result => {
      dialogRef.close(this.isSuccessful);
    });
  }

  username: string;
  isSuccessful = false;
  goalCreationFailed = false;
  errorMessage = '';
  submitted = false;
  goalForm: FormGroup = this.fb.group({
    type: ['', [Validators.required]],
    goalDescription: ['', [Validators.required]],
    helpInfo: ['', []]
  });

  onNoClick(): void {
    this.dialogRef.close(this.isSuccessful);
  }

  onSubmit(): void {
    this.submitted = true;
    if (!this.goalForm.valid) {
      return;
    } else {
      const type = this.goalForm.get('type').value;
      const goalDescription = this.goalForm.get('goalDescription').value;
      const helpInfo = this.goalForm.get('helpInfo').value;
      const updatedWeeklyGoal: WeeklyGoal = new WeeklyGoal(type, goalDescription, helpInfo, 0);
      this.userService.createGoal(updatedWeeklyGoal).subscribe(data => {
        this.isSuccessful = true;
        this.goalCreationFailed = false;
      }, err => {
        this.errorMessage = err.error.message;
        this.errorMessage = 'Error: Goal already exists!';
        this.goalCreationFailed = true;
      });
    }
  }
}

@Component({
  selector: 'app-edit-goal-dialog',
  templateUrl: 'app-edit-goal-dialog.html',
})
export class EditGoalDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<EditGoalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditDialogData, private fb: FormBuilder, private userService: UserService) {
    this.dialogRef.backdropClick().subscribe(result => {
      dialogRef.close(this.isSuccessful);
    });
  }

  username: string;
  isSuccessful = false;
  goalCreationFailed = false;
  errorMessage = '';
  submitted = false;
  goalForm: FormGroup = this.fb.group({
    type: ['', [Validators.required]],
    goalDescription: ['', [Validators.required]],
    helpInfo: ['', []]
  });

  onNoClick(): void {
    this.dialogRef.close(this.isSuccessful);
  }

  onSubmit(): void {
    this.submitted = true;
    if (!this.goalForm.valid) {
      return;
    } else {
      const type = this.goalForm.get('type').value;
      const goalDescription = this.goalForm.get('goalDescription').value;
      const helpInfo = this.goalForm.get('helpInfo').value;
      const updatedWeeklyGoal: WeeklyGoal = new WeeklyGoal(type, goalDescription, helpInfo, this.data.id);
      this.userService.editGoal(updatedWeeklyGoal).subscribe(data => {
        this.isSuccessful = true;
        this.goalCreationFailed = false;
      }, err => {
        this.errorMessage = err.error.message;
        this.errorMessage = 'Error: Goal could not be updated!';
        this.goalCreationFailed = true;
      });
    }
  }
}


export interface AddDialogData {
  type: string;
  goalDescription: string;
  helpInfo: string;
}

export interface EditDialogData {
  id: number;
  type: string;
  goalDescription: string;
  help_info: string;
}

