import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../_services/user.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-admin-forgot-password',
  templateUrl: './admin-forgot-password.component.html',
  styleUrls: ['./admin-forgot-password.component.css']
})
export class AdminForgotPasswordComponent implements OnInit {

  requestFailed = false;
  requestSuccess = false;
  errorMessage = '';
  successMessage = '';
  email: string;
  submitted = false;

  requestForm: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
  });

  constructor(private userService: UserService, private fb: FormBuilder, private titleService: Title) {
    this.titleService.setTitle('Forgot Password');
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.submitted = true;
    if (!this.requestForm.valid) {
      return;
    } else {
      const email = this.requestForm.get('email').value;
      this.userService.requestAdministratorPasswordChange(email).subscribe(
        data => {
          if (data) {
            this.successMessage = data.message;
            this.requestSuccess = true;
          }
        },
        err => {
          this.errorMessage = err.error.message;
          this.requestSuccess = false;
          this.requestFailed = true;
        }
      );
    }
  }

}
