export class User {
  id: number;

  constructor(
    id: number,
  ) {
    this.id = id;

  }
}
