<div class="mat-elevation-z8">
  <h1>Videos</h1>
  <table mat-table [dataSource]="dataSource">

    <!-- Position Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID</th>
      <td mat-cell *matCellDef="let Video"> {{Video.id}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef> Title</th>
      <td mat-cell *matCellDef="let Video"> {{Video.videoName}} </td>
    </ng-container>

    <ng-container matColumnDef="subject">
      <th mat-header-cell *matHeaderCellDef> Subject</th>
      <td mat-cell *matCellDef="let Video"> {{Video.videoSubject}} </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef> Type</th>
      <td mat-cell *matCellDef="let Video"> {{Video.videoType}} </td>
    </ng-container>

    <ng-container matColumnDef="url">
      <th mat-header-cell *matHeaderCellDef> URL</th>
      <td mat-cell *matCellDef="let Video"> {{Video.videoUrl}} </td>
    </ng-container>


    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button mat-stroked-button (click)="openCreateDialog()">Add Video</button>
      </th>
      <td mat-cell *matCellDef="let Video">
        <button mat-icon-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{Video: Video}" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
      </td>
    </ng-container>


    <mat-menu #menu="matMenu">
      <ng-template matMenuContent let-Video="Video">
        <button mat-menu-item (click)="openEditDialog(Video)">
          <mat-icon>edit</mat-icon>
          <span>Edit</span>
        </button>
        <button mat-menu-item (click)="openDeleteDialog(Video)">
          <mat-icon>delete</mat-icon>
          <span>Delete</span>
        </button>

      </ng-template>
    </mat-menu>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <button mat-icon-button aria-label="Example icon button with a vertical three dot icon">
      <mat-icon>more_vert</mat-icon>
    </button>
  </table>
  <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
    <mat-progress-spinner
      color="primary"
      mode="indeterminate">
    </mat-progress-spinner>
  </mat-card>
  <!--      <mat-paginator [pageSize]="10" showFirstLastButtons></mat-paginator>-->
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

</div>
