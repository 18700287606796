import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-password-reset-success-message',
  templateUrl: './password-reset-success-message.component.html',
  styleUrls: ['./password-reset-success-message.component.css']
})
export class PasswordResetSuccessMessageComponent implements OnInit {
  isAdmin = false;

  constructor(private location: Location, private router: Router, private titleService: Title) {
    this.titleService.setTitle('Reset Successful');
  }

  ngOnInit(): void {
    const url = window.location.toString().split('/');
    this.isAdmin = url[url.length - 1] === 'admin';
  }

  goToLogin(): void {
    this.router.navigate(['home']);
  }

}
