import {Component, OnInit} from '@angular/core';
import {TokenStorageService} from './_services/token-storage.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  private roles: string[] = [];
  isLoggedIn = false;
  showBaseBoard = false;
  showSuperBoard = false;
  showMasterBoard = false;
  username?: string;

  constructor(private tokenStorageService: TokenStorageService, private router: Router) {
  }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      this.roles = user.roles;

      this.showBaseBoard = this.roles.includes('ROLE_BASE');
      this.showSuperBoard = this.roles.includes('ROLE_SUPER');
      this.showMasterBoard = this.roles.includes('ROLE_MASTER');

      this.username = user.username;
    }
    // else{
    // console.log(this.router.url);
    // this.router.navigate(['login']);
    // }
  }

  logout(): void {
    this.tokenStorageService.signOut();
    window.location.href = '';
  }

  profile(): void {
    this.router.navigate(['profile']);
  }

  isUserPasswordReset(): boolean {
    return this.router.url.includes('updatePassword');
  }

  isPasswordResetRequest(): boolean {
    return this.router.url.includes('forgotPassword');
  }

  isPasswordResetSuccess(): boolean {
    return this.router.url.includes('resetSuccess');
  }
}
