<mat-card>
  <mat-card-content>
    <div class="header">
      <h1>PASSWORD RESET</h1>
    </div>
    <form (keyup.enter)="onSubmit()" name="resetForm" [formGroup]="resetForm">

      <div>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Password</mat-label>
          <input formControlName="password" matInput [type]=" hide ? 'password' : 'text'" required/>
          <button mat-icon-button matSuffix (click)="changeVisibility($event)" [attr.aria-label]="'Hide Password'"
                  [attr.aria-pressed]="hide">
            <mat-icon>
              {{hide ? 'visibility_off' : 'visibility'}}
            </mat-icon>
          </button>
          <ng-container
            *ngIf="!resetForm.controls['password'].valid && (resetForm.controls['password'].dirty || resetForm.controls['password'].touched)">
            <ng-container *ngIf="resetForm.controls['password'].errors.required">
              <mat-error>
                Password is required
              </mat-error>
            </ng-container>
            <ng-container
              *ngIf="resetForm.controls['password'].errors.minlength && !resetForm.controls['password'].errors.required">
              <mat-error>
                Password must be at least 6 characters
              </mat-error>
            </ng-container>
          </ng-container>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Confirm Password</mat-label>
          <input formControlName="confirmPassword" matInput [type]=" hide ? 'password' : 'text'" required/>
          <button mat-icon-button matSuffix (click)="changeVisibility($event)" [attr.aria-label]="'Hide Password'"
                  [attr.aria-pressed]="hide">
            <mat-icon>
              {{hide ? 'visibility_off' : 'visibility'}}
            </mat-icon>
          </button>
          <ng-container
            *ngIf="!resetForm.controls['confirmPassword'].valid && (resetForm.controls['confirmPassword'].dirty || resetForm.controls['confirmPassword'].touched)">
            <ng-container
              *ngIf="resetForm.controls['confirmPassword'].errors.notEquivalent && !resetForm.controls['confirmPassword'].errors.required">
              <mat-error>
                Passwords do not match
              </mat-error>
            </ng-container>
          </ng-container>
        </mat-form-field>


        <div class="alert alert-danger" *ngIf="submitted && resetFailed">
          Reset Token is invalid. Please request a new Reset Token.
        </div>
        <div class="alert alert-success" *ngIf="isSuccessful && !resetFailed && !isUserPage">
          Password reset successfully! <br><a routerLink="home">Go to sign in.</a>
        </div>

        <div class="alert alert-success" *ngIf="isSuccessful && !resetFailed && isUserPage">
          Password reset successfully!
        </div>
        <br>
      </div>
      <button (click)="onSubmit()" class="wide" mat-flat-button color="primary">Reset Password</button>
    </form>
  </mat-card-content>
</mat-card>
