import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import {ActivitiesComponent, AddActivityDialogComponent, EditActivityDialogComponent, DeleteActivityDialogComponent} from './activities/activities.component';
import {MatTableModule} from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { authInterceptorProviders } from './_helpers/auth.interceptor';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {AddGoalDialogComponent, EditGoalDialogComponent, WeeklyGoalsComponent} from './weekly_goals/weekly_goals.component';
import {AddFoodDialogComponent, FoodViewComponent} from './food_view/food_view.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import { AllUsersComponent } from './all-users/all-users.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import {AddArticleDialogComponent, ArticlesComponent, EditArticleDialogComponent, DeleteArticleDialogComponent} from './articles/articles.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { UserPasswordResetComponent } from './user-password-reset/user-password-reset.component';
import {MatCardModule} from '@angular/material/card';
import {MatSelectModule} from '@angular/material/select';
import { AdminForgotPasswordComponent } from './admin-forgot-password/admin-forgot-password.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {AddVideoDialogComponent, DeleteVideoDialogComponent, EditVideoDialogComponent, VideosComponent} from './videos/videos.component';
import { PasswordResetSuccessMessageComponent } from './password-reset-success-message/password-reset-success-message.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    ProfileComponent,
    ActivitiesComponent,
    WeeklyGoalsComponent,
    FoodViewComponent,
    AllUsersComponent,
    AddActivityDialogComponent,
    EditActivityDialogComponent,
    AddGoalDialogComponent,
    EditGoalDialogComponent,
    AddFoodDialogComponent,
    UnauthorizedComponent,
    ArticlesComponent,
    AddArticleDialogComponent,
    EditArticleDialogComponent,
    DeleteArticleDialogComponent,
    DeleteActivityDialogComponent,
    UserPasswordResetComponent,
    AdminForgotPasswordComponent,
    VideosComponent,
    AddVideoDialogComponent,
    EditVideoDialogComponent,
    DeleteVideoDialogComponent,
    PasswordResetSuccessMessageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatListModule,
    MatMenuModule,
    MatDialogModule,
    MatInputModule,
    MatCheckboxModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSelectModule,
    MatToolbarModule
  ],
  providers: [authInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }
