export class Video {
  id: number;
  videoName: string;
  videoSubject: string;
  videoType: string;
  videoUrl: string;
  visible: boolean;

  constructor(
    id: number,
    videoName: string,
    videoSubject: string,
    videoType: string,
    videoUrl: string,
  ) {
    this.id = id;
    this.videoName = videoName;
    this.videoSubject = videoSubject;
    this.videoType = videoType;
    this.videoUrl = videoUrl;
  }
}
