<mat-toolbar color="primary">
  <span routerLink="home">ENACT</span>
  <span class="example-spacer"></span>
  <ng-container *ngIf="isLoggedIn">
    <div>
      <ng-container *ngIf="isLoggedIn">
        <button mat-button [matMenuTriggerFor]="menu">Logged in as: {{username}}
          <mat-icon class="example-icon" aria-hidden="false" aria-label="Example user verified icon">expand_more
          </mat-icon>
        </button>
        <!--        <a mat-button> Log Out</a>-->
      </ng-container>
    </div>
  </ng-container>
</mat-toolbar>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="profile()">Profile</button>
  <button mat-menu-item (click)="logout()">Log Out</button>
</mat-menu>

<ng-container *ngIf="isLoggedIn">
  <mat-sidenav-container>
    <mat-sidenav #sidenav mode="side" class="example-sidenav" [ngStyle]="{ 'width.em': 15 }" opened="true">
      <!--            <div class="logomain">Logo Part</div>-->
      <mat-nav-list>

        <ng-container *ngIf="showBaseBoard">
          <mat-list-item routerLink="users" routerLinkActive="active">
            Users
          </mat-list-item>

        </ng-container>

        <ng-container *ngIf="showSuperBoard">
          <mat-list-item routerLink="activities" routerLinkActive="active">
            Activity Options
          </mat-list-item>

          <mat-list-item routerLink="goals" routerLinkActive="active">
            Goals
          </mat-list-item>

          <mat-list-item routerLink="foods" routerLinkActive="active">
            Food
          </mat-list-item>

          <mat-list-item routerLink="articles" routerLinkActive="active">
            Articles
          </mat-list-item>

          <mat-list-item routerLink="videos" routerLinkActive="active">
            Videos
          </mat-list-item>
        </ng-container>

        <ng-container *ngIf="showMasterBoard">
          <mat-list-item routerLink="register" routerLinkActive="active">
            Create new Admin
          </mat-list-item>

        </ng-container>
      </mat-nav-list>

    </mat-sidenav>

    <div class="example-sidenav-content">
      <router-outlet></router-outlet>
    </div>


  </mat-sidenav-container>
</ng-container>

<ng-container *ngIf="isUserPasswordReset() && !isLoggedIn">
  <app-user-password-reset></app-user-password-reset>
</ng-container>

<ng-container *ngIf="isPasswordResetRequest() && !isLoggedIn">
  <app-admin-forgot-password></app-admin-forgot-password>
</ng-container>

<ng-container *ngIf="isPasswordResetSuccess() && !isLoggedIn">
  <app-password-reset-success-message></app-password-reset-success-message>
</ng-container>


<ng-container *ngIf="!isLoggedIn && !isUserPasswordReset() && !isPasswordResetRequest() && !isPasswordResetSuccess()">
  <app-login></app-login>
</ng-container>

<ng-container *ngIf="!isLoggedIn">
  <mat-toolbar class="gap" color="primary">
  </mat-toolbar>
</ng-container>

<ng-container *ngIf="isLoggedIn">
  <mat-toolbar color="primary">
  </mat-toolbar>
</ng-container>
