import {Component, OnInit} from '@angular/core';
import {AuthService} from '../_services/auth.service';
import {TokenStorageService} from '../_services/token-storage.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  // form: any = {
  //   username: null,
  //   email: null,
  //   password: null
  // };
  username: string;
  isSuccessful = false;
  isSignUpFailed = false;
  errorMessage = '';
  hide = true;
  submitted = false;
  registerForm: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
    role: ['', [Validators.required]]
  }, {validator: this.checkIfMatchingPasswords('password', 'confirmPassword'), });

  // tslint:disable-next-line:max-line-length
  constructor(private authService: AuthService, private tokenStorageService: TokenStorageService, private fb: FormBuilder, private titleService: Title) {
    this.titleService.setTitle('Register New Admin');
  }

  ngOnInit(): void {
    this.username = this.tokenStorageService.getUser().username;
  }

  onSubmit(): void {
    this.submitted = true;
    if (!this.registerForm.valid) {
      return;
    } else {
      const email = this.registerForm.get('email').value;
      const password = this.registerForm.get('password').value;
      const role = this.registerForm.get('role').value;
      this.authService.register(role, email, password).subscribe(
        data => {
          this.isSuccessful = true;
          this.isSignUpFailed = false;
        },
        err => {
          this.errorMessage = err.error.message;
          this.isSignUpFailed = true;
        }
      );
    }
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string): any {
    return (group: FormGroup) => {
      const passwordInput = group.controls[passwordKey];
      const passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({notEquivalent: true});
      }
      else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }


  changeVisibility(event): void {
    if (event.clientX !== 0) {
      this.hide = !this.hide;
    }
  }
}

